import { ActivityTimeSeriesGranularity } from '../api/insights';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../components/Card';
import { chartColours } from '../constants';
import { ActivityTimeSeriesChart } from './charts/ActivityTimeSeriesChart';
import { ActivityTypeBarChart } from './charts/ActivityTypeBarChart';
import { ActivityTypePieChart } from './charts/ActivityTypePieChart';
import { ActivityTypeTotalRepsTimeSeriesChart } from './charts/ActivityTypeTotalRepsTimeSeriesChart';
import { ActivityTypeTimeSeriesDataPoint } from './hooks/useDashboardInsights';
import { useGranularityFormatters } from './hooks/useGranularityFormatters';

type PatientsChartsProps = {
  activityTypeData: Array<Record<string, unknown>>;
  activityTimeSeriesData: Array<Record<string, unknown>>;
  activityTypeTotalRepsTimeSeriesData: Array<ActivityTypeTimeSeriesDataPoint>;
  granularity: ActivityTimeSeriesGranularity;
};

export function PatientsCharts({
  activityTypeData,
  activityTimeSeriesData,
  activityTypeTotalRepsTimeSeriesData,
  granularity
}: PatientsChartsProps) {
  const { tickFormatter, titleFormatter } = useGranularityFormatters({ granularity });

  return (
    <>
      <div className="flex flex-col gap-5 pb-5 md:flex-row">
        <Card className="w-full">
          <CardHeader>
            <CardTitle>{titleFormatter('Repetitions')}</CardTitle>
            <CardDescription>The total number of repetitions completed by patients</CardDescription>
          </CardHeader>
          <CardContent>
            <ActivityTimeSeriesChart
              data={activityTimeSeriesData}
              dataKey="totalReps"
              labelKey="timestamp"
              tickFormatter={tickFormatter}
            />
          </CardContent>
        </Card>

        <Card className="w-full">
          <CardHeader>
            <CardTitle>{titleFormatter('Time on task')}</CardTitle>
            <CardDescription>The total duration of all patient activities in minutes</CardDescription>
          </CardHeader>
          <CardContent>
            <ActivityTimeSeriesChart
              data={activityTimeSeriesData}
              dataKey="totalTimeTaken"
              labelKey="timestamp"
              tickFormatter={tickFormatter}
              fill={chartColours[1]}
            />
          </CardContent>
        </Card>
      </div>

      <div className="flex flex-col gap-5 pb-5 md:flex-row">
        <Card className="w-full">
          <CardHeader>
            <CardTitle>{titleFormatter('Active patients')}</CardTitle>
            <CardDescription>The total number of patients who completed at least one activity</CardDescription>
          </CardHeader>
          <CardContent>
            <ActivityTimeSeriesChart
              data={activityTimeSeriesData}
              dataKey="activePatientCount"
              labelKey="timestamp"
              tickFormatter={tickFormatter}
              fill={chartColours[2]}
            />
          </CardContent>
        </Card>

        <Card className="w-full">
          <CardHeader>
            <CardTitle>{titleFormatter('Sessions')}</CardTitle>
            <CardDescription>The total number of patient therapy sessions</CardDescription>
          </CardHeader>
          <CardContent>
            <ActivityTimeSeriesChart
              data={activityTimeSeriesData}
              dataKey="sessionCount"
              labelKey="timestamp"
              tickFormatter={tickFormatter}
              fill={chartColours[3]}
            />
          </CardContent>
        </Card>
      </div>

      <div className="flex flex-col gap-5 pb-5 md:flex-row">
        <Card className="w-full">
          <CardHeader>
            <CardTitle>Activities by activity</CardTitle>
            <CardDescription>The total number of activites for each activity type</CardDescription>
          </CardHeader>
          <CardContent>
            <ActivityTypePieChart data={activityTypeData} dataKey="activityCount" />
          </CardContent>
        </Card>

        <Card className="w-full">
          <CardHeader>
            <CardTitle>Repetitions by activity</CardTitle>
            <CardDescription>The total number of repetitions for each activity type</CardDescription>
          </CardHeader>
          <CardContent>
            <ActivityTypePieChart data={activityTypeData} dataKey="totalReps" />
          </CardContent>
        </Card>
      </div>

      <div className="flex flex-col gap-5 pb-5 md:flex-row">
        <Card className="w-full">
          <CardHeader>
            <CardTitle>{titleFormatter('Repetitions by activity')}</CardTitle>
            <CardDescription>The total number of repetitions for each activity</CardDescription>
          </CardHeader>
          <CardContent>
            <ActivityTypeTotalRepsTimeSeriesChart
              data={activityTypeTotalRepsTimeSeriesData}
              tickFormatter={tickFormatter}
            />
          </CardContent>
        </Card>
      </div>

      <div className="flex flex-col gap-5 pb-5 md:flex-row">
        <Card className="w-full">
          <CardHeader>
            <CardTitle>Avg time on task by activity</CardTitle>
            <CardDescription>The average duration of an activity in seconds</CardDescription>
          </CardHeader>
          <CardContent>
            <ActivityTypeBarChart data={activityTypeData} dataKey="avgTimeTaken" />
          </CardContent>
        </Card>

        <Card className="w-full">
          <CardHeader>
            <CardTitle>Avg repetitions by activity</CardTitle>
            <CardDescription>The average number of repetitions in an activity</CardDescription>
          </CardHeader>
          <CardContent>
            <ActivityTypeBarChart data={activityTypeData} dataKey="avgTotalReps" />
          </CardContent>
        </Card>

        <Card className="w-full">
          <CardHeader>
            <CardTitle>Avg RPE by activity</CardTitle>
            <CardDescription>The average rate of perceived exertion for an activity</CardDescription>
          </CardHeader>
          <CardContent>
            <ActivityTypeBarChart data={activityTypeData} dataKey="avgFeedbackScore" />
          </CardContent>
        </Card>
      </div>
    </>
  );
}
