import { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoIosAddCircle } from 'react-icons/io';
import { MdDelete, MdEdit } from 'react-icons/md';
import { User } from '../../api';
import { useAuthentication } from '../../auth';
import { Badge } from '../../components/Badge';
import { Button } from '../../components/button';
import { IconButton } from '../../components/button/IconButton';
import { Heading } from '../../components/Heading';
import { TextInput } from '../../components/inputs/TextInput';
import { NoCapture } from '../../components/NoCapture';
import {
  ListView,
  ListViewColumnDefinition,
  ListViewFieldProps,
  rowsFromColumnDefinitions
} from '../../components/table/listview';
import { useDebounce } from '../../hooks/useDebounce';
import { useSearchUsers } from '../hooks/useSearchUsers';
import { DeleteUserModal } from './DeleteUserModal';
import { EditUserModal } from './EditUserModal';
import { InviteUserModal } from './InviteUserModal';

const columnDefinitions: ListViewColumnDefinition[] = [
  {
    field: 'email',
    header: 'Email Address',
    fieldComponent: ({ value }) => <NoCapture>{value as string}</NoCapture>
  },
  {
    field: 'firstName',
    header: 'First Name',
    fieldComponent: ({ value }) => <NoCapture>{value as string}</NoCapture>
  },
  {
    field: 'lastName',
    header: 'Last Name',
    fieldComponent: ({ value }) => <NoCapture>{value as string}</NoCapture>
  },
  {
    field: 'roles',
    header: 'Role',
    fieldComponent: ({ value }: ListViewFieldProps) => {
      const roles = value as string[];
      return <span className="capitalize">{roles[0]}</span>;
    }
  },
  {
    field: 'enabled',
    header: 'Status',
    fieldComponent: ({ value }: ListViewFieldProps) => {
      const className = value ? 'text-green-500' : 'text-gray-400';
      return (
        <Badge variant="outline" className={className}>
          {value ? 'ENABLED' : 'DISABLED'}
        </Badge>
      );
    }
  },
  {
    field: 'id',
    header: '',
    fieldComponent: ({ dataItem }: ListViewFieldProps) => {
      const user = dataItem as User;
      return <UserActions {...user} />;
    }
  }
];

export const UsersList = () => {
  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const searchTerm = useDebounce(searchInput, 200);
  const { users, isLoading } = useSearchUsers({ searchTerm });

  const rows = rowsFromColumnDefinitions(users || [], columnDefinitions);

  return (
    <div className="flex flex-1 flex-col justify-between">
      {showModal && <InviteUserModal onClose={() => setShowModal(false)} />}

      <div className="flex items-center justify-between">
        <Heading size="l">Users</Heading>
        <div className="flex h-[54px] items-center gap-5 pl-5">
          <TextInput
            icon={FiSearch}
            initialValue={searchInput}
            placeholder="Search by name"
            onChange={setSearchInput}
            autoFocus
            testId="users-search-input"
          />
          <Button variant="primary" icon={IoIosAddCircle} iconLayout="start" onClick={() => setShowModal(true)}>
            Invite User
          </Button>
        </div>
      </div>
      <div className="mt-5 h-[70vh] overflow-auto">
        <ListView rows={rows} columns={columnDefinitions} isLoading={!rows.length && isLoading} testId="users-list" />
      </div>
    </div>
  );
};

const UserActions = (user: User) => {
  const { id } = useAuthentication();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <div className="flex">
      {showEditModal && <EditUserModal {...user} onClose={() => setShowEditModal(false)} />}
      {showDeleteModal && (
        <DeleteUserModal userId={user.id} email={user.email} onClose={() => setShowDeleteModal(false)} />
      )}

      <IconButton
        onClick={() => setShowEditModal(true)}
        variant={'transparent'}
        icon={MdEdit}
        testId="edit-user-action-button"
      />

      <IconButton
        onClick={() => setShowDeleteModal(true)}
        variant={'danger'}
        icon={MdDelete}
        disabled={user.id === id}
        testId="delete-user-action-button"
      />
    </div>
  );
};
