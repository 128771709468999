import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { chartColours } from '../../constants';
import { cn } from '../../util';
import { ChartTooltip } from './ChartTooltip';

export interface ActivityTimeSeriesChart {
  data: Array<Record<string, unknown>>;
  dataKey?: string;
  labelKey?: string;
  animate?: boolean;
  fill?: string;
  tickFormatter?: (value: string, index: number) => string;
  className?: string;
}

export function ActivityTimeSeriesChart({
  data,
  dataKey,
  labelKey,
  animate,
  fill,
  tickFormatter,
  className
}: ActivityTimeSeriesChart) {
  return (
    <div className={cn('h-[300px]', className)}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} layout="horizontal" margin={{ top: 24, right: 40 }} accessibilityLayer>
          <XAxis
            dataKey={labelKey ?? 'label'}
            type="category"
            tickLine={false}
            fontSize={10}
            axisLine={false}
            stroke="#fff"
            minTickGap={35}
            interval="preserveStartEnd"
            tickMargin={8}
            tickFormatter={tickFormatter}
          />

          <YAxis type="number" axisLine={false} tickLine={false} stroke="#fff" fontSize={10} width={40} />

          <CartesianGrid vertical={false} strokeDasharray="3 3" strokeOpacity={0.2} stroke="#fff" />

          <Bar dataKey={dataKey ?? 'value'} radius={5} fill="#fff" isAnimationActive={!!animate}>
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={fill ?? chartColours[0]} radius={0} />
            ))}
          </Bar>

          <Tooltip
            content={<ChartTooltip formatter={(value) => tickFormatter?.(value, 0) ?? value} />}
            cursor={{ fill: fill ?? chartColours[0], fillOpacity: 0.1 }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
