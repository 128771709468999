import { getQueryCacheKey } from '../util';
import { tms } from './tms';

const cacheTag = 'Insights';

interface KpiParams {
  patientIds?: number[];
  activityTypeIds?: string[];
  dateFrom?: string;
  dateTo?: string;
}

export interface GetPatientActivitySummaryStatisticsResult {
  [index: string]: number | undefined;

  averageActivitiesPerSession?: number;
  averageRepsPerSession?: number;
  averageTimeTakenPerSession?: number;
  averageTotalRepsCompleted?: number;
  averageTotalTimeTaken?: number;
}

export type ActivityTimeSeriesGranularity = 'hour' | 'day' | 'week';

export type ActivityTimeSeriesResult = {
  granularity: ActivityTimeSeriesGranularity;
  timeRange: {
    from?: string;
    to?: string;
  };
  data: Array<{
    activePatientCount: number;
    activityTypeId: string | null;
    patientSessionId: string | null;
    avgFeedbackScore: number;
    sessionCount: number;
    timestamp: string;
    totalReps: number;
    totalTimeTaken: number;
  }>;
};

export type GetInsightsDashboardParams = KpiParams & {
  granularity?: ActivityTimeSeriesGranularity;
  activityTypeIds?: string[];
};

export type GetInsightsDashboardResult = {
  activityTypes: Array<{
    activityTypeId: string;
    gameId?: string;
    avgTimeTaken: number;
    avgTotalReps: number;
    avgFeedbackScore: number;
  }>;
  activityTimeSeries: ActivityTimeSeriesResult;
  activityTypeTimeSeries: ActivityTimeSeriesResult;
  activitySessionTimeSeries: ActivityTimeSeriesResult;
};

const insights = tms.injectEndpoints({
  endpoints: (builder) => ({
    getInsightsDashboard: builder.query<GetInsightsDashboardResult, GetInsightsDashboardParams>({
      query: (params) => ({
        url: `insights/dashboard`,
        method: 'GET',
        params: {
          ...params,
          patientIds: params.patientIds?.join(','),
          activityTypeIds: params.activityTypeIds?.join(',') || undefined,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }),
      providesTags: (_result, _error, params) => [{ type: cacheTag, id: getQueryCacheKey(params, 'dashboard') }]
    }),
    getPatientActivitySummaryStatistics: builder.query<GetPatientActivitySummaryStatisticsResult, KpiParams>({
      query: (params) => ({
        url: `patients/insights/summary-statistics`,
        method: 'GET',
        params: {
          ...params,
          patientIds: params.patientIds?.join(','),
          activityTypeIds: params.activityTypeIds?.join(',') || undefined
        }
      }),
      providesTags: (_result, _error, params) => [
        { type: cacheTag, id: getQueryCacheKey(params, 'summary-statistics') }
      ]
    })
  }),
  overrideExisting: false
});

export const { useQuery: useGetPatientActivitySummaryStatisticsQuery } =
  insights.endpoints.getPatientActivitySummaryStatistics;

export const { useQuery: useGetInsightsDashboardQuery } = insights.endpoints.getInsightsDashboard;
