import { Skeleton } from '../../../components/Skeleton';
import { SummaryStatisticsSkeleton } from './SummaryStatistics';

export function PatientDetailsSkeleton() {
  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <Skeleton className="h-[50px] w-[90px]" />
        <div className="flex gap-5">
          <Skeleton className="h-[50px] w-[140px]" />
          <Skeleton className="h-[50px] w-[140px]" />
        </div>
      </div>
      <div className="flex items-center justify-between pt-6">
        <div>
          <Skeleton className="h-[40px] w-[250px]" />
          <Skeleton className="mt-2 h-[20px] w-[150px]" />
        </div>

        <div className="flex flex-row">
          <Skeleton className="me-4 h-[40px] w-[350px]" />
          <Skeleton className="h-[40px] w-[350px]" />
        </div>
      </div>

      <SummaryStatisticsSkeleton />

      <div className="flex justify-center gap-5 pb-5">
        <Skeleton className="h-[425px] w-2/3" />
        <Skeleton className="h-[425px] w-1/3" />
      </div>

      <div className="flex justify-center gap-5 pb-5">
        <Skeleton className="h-[425px] w-2/3" />
        <Skeleton className="h-[425px] w-1/3" />
      </div>

      <div className="flex justify-center gap-5 pb-5">
        <Skeleton className="h-[425px] w-2/3" />
        <Skeleton className="h-[425px] w-1/3" />
      </div>

      <div className="flex justify-center gap-5 pb-5">
        <Skeleton className="h-[425px] w-full" />
      </div>

      <div className="flex justify-center gap-5 pb-5">
        <Skeleton className="h-[425px] w-full" />
        <Skeleton className="h-[425px] w-full" />
      </div>

      <div className="flex justify-center gap-5 pb-5">
        <Skeleton className="h-[600px] w-full" />
      </div>
    </div>
  );
}
