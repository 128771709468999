import { chartColours } from '../../constants';

export interface ChartTooltipProps {
  active?: boolean;
  payload?: Array<{ name?: string; value: number; fill?: string }>;
  label?: string;
  formatter?: (value: string) => string;
  stacked?: boolean;
}

export function ChartTooltip({ active, payload, label, stacked, formatter }: ChartTooltipProps) {
  if (!active || !payload || !payload.length) {
    return null;
  }

  let formattedLabel = label || payload[0].name;
  if (formattedLabel && formatter) {
    formattedLabel = formatter(formattedLabel);
  }

  const value = payload.reduce((acc, { value }) => acc + value, 0);

  return (
    <div className="bg-primary rounded-md p-2 text-lg text-white shadow-2xl shadow-black">
      <p className="flex justify-between">
        <span className="me-4 font-bold">{formattedLabel}</span>
        <span className="">{value}</span>
      </p>

      {stacked && (
        <>
          <hr className="my-2 text-white" />

          <ul>
            {payload.map((entry, index) => (
              <li className="flex justify-between" key={entry.name}>
                <span style={{ color: entry.fill ?? chartColours[index] }}>{entry.name}</span>
                <span className="ms-4">{entry.value}</span>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
