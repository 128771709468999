import { subDays } from 'date-fns';
import { DateRange, RangeType } from 'rsuite/esm/DateRangePicker';

type DateRangeType = 'today' | 'yesterday' | 'last7days' | 'last30days' | 'month' | 'all';

export const dateRanges: Record<DateRangeType, RangeType & { value: DateRange | null }> = {
  today: {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  yesterday: {
    label: 'Yesterday',
    value: [subDays(new Date(), 1), subDays(new Date(), 1)],
    placement: 'left'
  },
  last7days: {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  last30days: {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left'
  },
  month: {
    label: 'This month',
    value: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()],
    placement: 'left'
  },
  all: {
    label: 'All time',
    value: null,
    placement: 'left'
  }
};

export const dateRangeChoices: RangeType[] = Object.values(dateRanges);
