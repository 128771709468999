import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CustomProvider as RSuiteProvider } from 'rsuite';
import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import { App } from './App.tsx';
import { ErrorBoundary } from './components/ErrorBoundary.tsx';
import { GlobalStyles } from './GlobalStyles.tsx';
import { persistor, store } from './store';
import { theme } from './theme';

if (import.meta.env.VITE_PUBLIC_POSTHOG_KEY) {
  posthog.init(import.meta.env.VITE_PUBLIC_POSTHOG_KEY, {
    api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST
  });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <Normalize />
              <GlobalStyles />
              <ErrorBoundary>
                <RSuiteProvider>
                  <App />
                </RSuiteProvider>
              </ErrorBoundary>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </React.Suspense>
    </PostHogProvider>
  </React.StrictMode>
);
