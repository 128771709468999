import { Modal } from '../../components/Modal.tsx';
import { ErrorMessage, InputLabel, TextInput } from '../../components/inputs/TextInput.tsx';
import { styled } from 'styled-components';
import { useEffect, useState } from 'react';
import { useValidator } from '../../hooks/useValidator.ts';
import { constants } from '../../constants.ts';
import { Button } from '../../components/button';
import { Spinner } from '../../components/Spinner.tsx';
import { useInviteUserMutation, UserInvite } from '../../api';
import { Select } from '../../components/inputs/Select.tsx';

type InviteUserModalProps = {
  onClose: () => void;
};

const UserForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px 20px 10px;
`;

const InputContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;

const InputColumns = styled.div`
  display: flex;
  gap: 20px;

  > * {
    flex: 1;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const FormErrorContainer = styled.div`
  margin-top: 20px;
`;

const LoadingContainer = styled.div`
  margin-left: 20px;
`;

export const InviteUser = ({ onClose }: InviteUserModalProps) => {
  const [formError, setFormError] = useState('');
  const [inviteUser, { isLoading: isUpdating, error, isSuccess }] = useInviteUserMutation();

  const [userInvite, setUserInvite] = useState<UserInvite>({
    email: '',
    firstName: '',
    lastName: '',
    roles: ['clinician']
  });

  const { validator, validate } = useValidator({
    validEmail: {
      message: 'Please enter a valid email address',
      rule: (val: string) => {
        return constants.regExp.email.test(val);
      }
    },
    validName: {
      message: 'Please enter a valid name',
      rule: (val: string) => {
        return constants.regExp.findName.test(val);
      }
    },
    validRole: {
      message: 'Please select a valid role',
      rule: (val: string[]) => {
        return val.length > 0 && val.every((role) => constants.roles.includes(role));
      }
    }
  });

  const onChange = (property: string, value: string | string[]) => {
    setUserInvite({ ...userInvite, [property]: value });
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
    } else if (error) {
      setFormError('Error inviting user');
    }
  }, [isSuccess, error, onClose]);

  const submit = async () => {
    if (!validate()) {
      return;
    }

    await inviteUser(userInvite);
  };

  return (
    <UserForm>
      <InputColumns>
        <InputContainer>
          <TextInput
            label="Email address"
            placeholder="Enter address"
            onChange={(value) => onChange('email', value)}
            errorMessage={validator.current.message('email', userInvite.email, 'required|validEmail')}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel htmlFor="input-field">Role</InputLabel>
          <Select
            onChange={(value) => onChange('roles', [value.currentTarget.value])}
            value={userInvite.roles.length > 0 ? userInvite.roles[0] : 'clinician'}
          >
            <option value="admin">Admin</option>
            <option value="clinician">Clinician</option>
          </Select>
          <ErrorMessage>{validator.current.message('roles', userInvite.roles, 'required|validRole')}</ErrorMessage>
        </InputContainer>
      </InputColumns>
      <InputColumns>
        <InputContainer>
          <TextInput
            label="First name"
            placeholder="Enter first name"
            onChange={(value) => onChange('firstName', value)}
            errorMessage={validator.current.message('firstName', userInvite.firstName, 'required|validName')}
          />
        </InputContainer>
        <InputContainer>
          <TextInput
            label="Last name"
            placeholder="Enter last name"
            onChange={(value) => onChange('lastName', value)}
            errorMessage={validator.current.message('lastName', userInvite.lastName, 'required|validName')}
          />
        </InputContainer>
      </InputColumns>
      <ButtonContainer>
        <Button variant={'accent'} onClick={() => submit()} disabled={isUpdating}>
          Invite User
        </Button>
        {isUpdating && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
      </ButtonContainer>
      {formError && <FormErrorContainer>{formError}</FormErrorContainer>} {/*Move this to toast or similar*/}
    </UserForm>
  );
};

export const InviteUserModal = ({ onClose }: InviteUserModalProps) => {
  return (
    <Modal title="Invite User" onClose={onClose}>
      <InviteUser onClose={onClose} />
    </Modal>
  );
};
