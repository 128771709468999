import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getRpeFill } from '.';
import { cn } from '../../util';
import { ChartTooltip } from './ChartTooltip';

export interface ActivityAvgRPEChartProps {
  data: Array<Record<string, unknown>>;
  dataKey?: string;
  labelKey?: string;
  animate?: boolean;
  tickFormatter?: (value: string, index: number) => string;
  className?: string;
}

export function ActivityAvgRPEChart({
  data,
  dataKey,
  labelKey,
  animate,
  tickFormatter,
  className
}: ActivityAvgRPEChartProps) {
  return (
    <div className={cn('h-[300px]', className)}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data} margin={{ top: 24, left: 16, right: 24 }} accessibilityLayer>
          <XAxis
            dataKey={labelKey ?? 'label'}
            type="category"
            tickLine={false}
            fontSize={12}
            axisLine={false}
            stroke="#fff"
            minTickGap={24}
            tickMargin={12}
            interval="preserveStartEnd"
            tickFormatter={tickFormatter}
          />

          <YAxis type="number" axisLine={false} tickLine={false} stroke="#fff" fontSize={12} width={16} />

          <CartesianGrid vertical={false} strokeDasharray="3 3" strokeOpacity={0.2} stroke="#fff" />

          <Line
            isAnimationActive={!!animate}
            type="monotone"
            dataKey={dataKey ?? 'value'}
            stroke="#fff"
            strokeOpacity={1}
            strokeWidth={4}
            activeDot={<ActivityAvgRPEChartDot active />}
            dot={<ActivityAvgRPEChartDot />}
          />

          <Tooltip content={<ChartTooltip formatter={(value) => tickFormatter?.(value, 0) ?? value} />} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

const ActivityAvgRPEChartDot = ({
  cx,
  cy,
  value,
  active
}: {
  cx?: number;
  cy?: number;
  value?: number;
  active?: boolean;
}) => {
  if (!value) {
    return null;
  }

  const fill = getRpeFill(value);
  const radius = active ? 10 : 8;

  return <circle cx={cx} cy={cy} r={radius} fill={fill} />;
};
