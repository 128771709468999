import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { useExchangeAuthorizationCodeMutation } from '../../api/auth.ts';
import logo from '../../assets/bioliberty_logo.png';
import { Button } from '../../components/button';
import { Heading } from '../../components/Heading.tsx';
import { Spinner } from '../../components/Spinner.tsx';
import { Route } from '../../constants';
import Env from '../../Env.ts';
import { setToken } from '../../store/slices/auth.ts';
import { buildQueryParams } from '../../util';
import { localDevelopmentBaseUri } from '../index.ts';

const Layout = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const Logo = styled.img`
  flex: 0;
  padding-bottom: 3rem;
`;

const authRedirectUri = Env.IS_DEV ? `${localDevelopmentBaseUri}${Route.SignInCallback}` : undefined;

export const SignIn = () => {
  const location = useLocation();
  const loginMessage =
    location.state?.loggedOut === true ? 'You have been signed out.' : 'You are not currently signed in.';

  const onSignIn = useCallback(() => {
    const params = buildQueryParams({
      redirect_uri: Env.IS_DEV ? authRedirectUri : undefined
    });

    window.location.replace(`${Route.Authorize}${params}`);
  }, []);

  return (
    <Layout>
      <Logo src={logo} alt="logo" />
      <Heading size="m">{loginMessage}</Heading>
      <Button variant="accent" onClick={onSignIn}>
        Sign In
      </Button>
    </Layout>
  );
};

export const SignInCallback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const exchangedToken = useRef(false);
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const successfulCallback = code && state;

  const [exchangeAuthorizationCode, { error, isSuccess, data }] = useExchangeAuthorizationCodeMutation();

  useEffect(() => {
    if (!successfulCallback || exchangedToken.current) return;
    exchangedToken.current = true;

    exchangeAuthorizationCode({
      authorizationCode: code,
      state: state,
      redirectUri: authRedirectUri
    });
  }, [successfulCallback, code, state, exchangeAuthorizationCode]);

  useEffect(() => {
    if (!isSuccess) return;

    if (data && data.accessToken) {
      dispatch(setToken(data));
    }
  }, [isSuccess, data, dispatch]);

  if (isSuccess) {
    const returnPath = sessionStorage.getItem('returnPath');
    navigate(returnPath ?? Route.Home);
    return null;
  }

  return (
    <Layout>
      <Logo src={logo} alt="logo" />
      {successfulCallback && !error ? <SigningIn /> : <SignInFailed />}
    </Layout>
  );
};

const SigningIn = () => {
  return (
    <>
      <Heading size="m">Signing in...</Heading>
      <Spinner />
    </>
  );
};

const SignInFailed = () => {
  return (
    <>
      <Heading size="m">Something went wrong</Heading>
      <p>
        We were unable to sign you in. <NavLink to={'/sign-in'}>Try to sign in again</NavLink>
      </p>
    </>
  );
};
