import {
  differenceInDays,
  differenceInMilliseconds,
  differenceInWeeks,
  endOfDay,
  isToday,
  startOfDay,
  subMilliseconds
} from 'date-fns';
import { useMemo } from 'react';
import { ActivityTimeSeriesGranularity } from '../api/insights';

const emptyRange = {
  dateFrom: undefined,
  dateTo: undefined,
  prevRangeDateFrom: undefined,
  prevRangeDateTo: undefined,
  granularity: 'week' as ActivityTimeSeriesGranularity
};

function getGranularity(dateRange: [Date, Date]): ActivityTimeSeriesGranularity {
  const [start, end] = dateRange;
  const weeksDiff = differenceInWeeks(end, start);
  const daysDiff = differenceInDays(end, start);

  if (daysDiff <= 2) {
    return 'hour';
  } else if (weeksDiff <= 8) {
    return 'day';
  }

  return 'week';
}

export function useDateRange(dateRange?: [Date, Date] | null) {
  return useMemo(() => {
    if (!dateRange) {
      return emptyRange;
    }

    try {
      const [start, end] = dateRange;
      const rangeDuration = differenceInMilliseconds(end, start);
      const prevRangeEnd = subMilliseconds(start, 1); // Subtract 1ms to not overlap
      const prevRangeStart = subMilliseconds(prevRangeEnd, rangeDuration);

      return {
        dateFrom: startOfDay(start).toISOString(),
        dateTo: !isToday(end) ? endOfDay(end).toISOString() : undefined,
        prevRangeDateFrom: startOfDay(prevRangeStart).toISOString(),
        prevRangeDateTo: endOfDay(prevRangeEnd).toISOString(),
        granularity: getGranularity(dateRange)
      };
    } catch {
      return emptyRange;
    }
  }, [dateRange]);
}
