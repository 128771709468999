import { useEffect, useState } from 'react';
import { TextInput } from '../../components/inputs/TextInput.tsx';
import { Button } from '../../components/button';
import { styled } from 'styled-components';
import { useAddPatientMutation } from '../../api';
import { Spinner } from '../../components/Spinner.tsx';
import { useValidator } from '../../hooks/useValidator.ts';

type AddPatientFormProps = {
  closeModal: () => void;
};

const InputContainer = styled.div`
  margin-top: 20px;
  max-width: 400px;
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const PatientForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
`;

const FormErrorContainer = styled.div`
  margin-top: 20px;
`;

const LoadingContainer = styled.div`
  margin-left: 20px;
`;

export const AddPatientForm = ({ closeModal }: AddPatientFormProps) => {
  const [alias, setAlias] = useState('');
  const [formError, setFormError] = useState('');

  const { validator, validate } = useValidator({
    validAlias: {
      message: 'Please enter a valid unique patient identifier',
      rule: (val: string) => {
        return /\S+/.test(val);
      }
    }
  });

  const [addPatient, { isLoading: isUpdating, isSuccess, error: addError }] = useAddPatientMutation();

  const handleAliasChange = (e: string) => {
    setAlias(e);
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    } else if (addError) {
      if ('data' in addError) {
        setFormError((addError.data as { title: string }).title);
      } else {
        setFormError('Error adding patient');
      }
    }
  }, [isSuccess, addError, closeModal]);

  const submit = async () => {
    if (validate()) {
      try {
        const newPatient = { alias: alias.trim() };
        await addPatient(newPatient);
      } catch {
        setFormError('Error adding patient');
      }
    }
  };

  return (
    <PatientForm>
      <InputContainer>
        <TextInput
          label="Unique patient identifier*"
          placeholder="Enter unique patient identifier"
          onChange={handleAliasChange}
          errorMessage={validator.current.message('unique patient identifier', alias, 'required|validAlias')}
        />
      </InputContainer>
      <ButtonContainer>
        <Button variant="accent" onClick={() => submit()} disabled={isUpdating}>
          Add New Patient
        </Button>
        {isUpdating && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
      </ButtonContainer>
      {formError && <FormErrorContainer>{formError}</FormErrorContainer>} {/*Move this to toast or similar*/}
    </PatientForm>
  );
};
