import { GoPencil, GoTrash } from 'react-icons/go';
import { IoArrowBackOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/button';

export type PatientTopBarProps = {
  setShowEditModal: (show: boolean) => void;
  setShowDeleteModal: (show: boolean) => void;
};

export const PatientTopBar = ({ setShowEditModal, setShowDeleteModal }: PatientTopBarProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between">
      <Button variant="primary" icon={IoArrowBackOutline} onClick={() => navigate('..')}>
        Back
      </Button>
      <div className="flex gap-5">
        <Button variant="primary" icon={GoPencil} onClick={() => setShowEditModal(true)}>
          Edit Patient
        </Button>
        <Button variant="danger" icon={GoTrash} onClick={() => setShowDeleteModal(true)}>
          Delete Patient
        </Button>
      </div>
    </div>
  );
};
