import posthog from 'posthog-js';
import { useEffect } from 'react';
import { Outlet, useNavigationType } from 'react-router-dom';
import { useGetProfileQuery } from '../api';
import { Route } from '../constants';
import { AuthenticatedUserContext } from './AuthenticatedUserContext';

export const RequireAuthentication = () => {
  const navigationType = useNavigationType();
  const { data, isLoading, isFetching, error, isSuccess, refetch } = useGetProfileQuery();

  useEffect(() => {
    if (navigationType.toString() === 'POP') {
      refetch();
    }
  }, [navigationType, refetch]);

  useEffect(() => {
    if (data) {
      const { id, tenant, roles } = data;
      posthog.identify(id, { tenant, roles });
    }
  }, [data]);

  if (isLoading) {
    return;
  }

  if (!isSuccess || error || !data) {
    const returnPath = location.pathname + location.search;
    sessionStorage.setItem('returnPath', returnPath);
    location.replace(Route.SignIn);
    return;
  }

  return (
    <AuthenticatedUserContext.Provider value={data}>{!isFetching && <Outlet />}</AuthenticatedUserContext.Provider>
  );
};
