import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { tms } from '../api/tms.ts';
import { errorMiddleware } from './middlewares/error.middleware.ts';
import adminViewReducer, { AdminViewState } from './slices/adminView';
import authReducer from './slices/auth.ts';
import featureFlagsReducer, { FeatureFlagsState } from './slices/featureFlags';
import patientViewReducer, { PatientViewState } from './slices/patientView.ts';

const persistConfig = { key: 'root', storage };

const rootReducer = combineReducers({
  [tms.reducerPath]: tms.reducer,
  auth: persistReducer(persistConfig, authReducer),
  featureFlags: featureFlagsReducer,
  patientView: patientViewReducer,
  adminView: adminViewReducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'persist/FLUSH',
          'persist/REHYDRATE',
          'persist/PAUSE',
          'persist/PERSIST',
          'persist/PURGE',
          'persist/REGISTER'
        ]
      }
    }).concat([errorMiddleware, tms.middleware])
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer> & {
  featureFlags: FeatureFlagsState;
  patientView: PatientViewState;
  adminView: AdminViewState;
};

export type AppDispatch = typeof store.dispatch;
