import { desaturate, lighten } from 'polished';
import { ButtonHTMLAttributes, MouseEventHandler, PropsWithChildren } from 'react';
import { IconType } from 'react-icons';
import { CgSpinner } from 'react-icons/cg';
import { styled } from 'styled-components';
import { Icon } from '../Icon.tsx';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'primary' | 'accent' | 'transparent' | 'danger';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  'data-testid'?: string;
};

type IconLayout = 'start' | 'end';

type TextButtonProps = ButtonProps &
  PropsWithChildren & {
    icon?: IconType;
    iconLayout?: IconLayout;
  };

const PrimaryButton = styled.button`
  cursor: pointer;
  color: ${(props) => props.theme.typography.colors.primary};
  background: ${(props) => props.theme.layout.background};
  background: linear-gradient(135deg, #444d5b 0%, ${(props) => props.theme.layout.background} 100%);
  border: 2px solid ${(props) => props.theme.colors.accent};
  border-radius: 0.75rem;
  padding: 1em 1.5em;
  font-size: 1rem;
  font-weight: bold;
  transition:
    background 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  &:disabled {
    pointer-events: none;
    border-color: ${(props) => props.theme.colors.subtle};
    background: ${(props) => props.theme.colors.subtle};
    color: #cccccc;
  }

  &:hover {
    background: linear-gradient(
      135deg,
      ${(props) => desaturate(0.75, props.theme.colors.accent)} 0%,
      ${(props) => props.theme.layout.background} 100%
    );
  }
`;

const AccentedButton = styled(PrimaryButton)`
  background: ${(props) => props.theme.colors.accent};
  color: ${(props) => props.theme.typography.colors.secondary};

  &:hover {
    background: ${(props) => lighten(0.1, props.theme.colors.accent)};
    border-color: ${(props) => lighten(0.1, props.theme.colors.accent)};
  }
`;

const DangerButton = styled(PrimaryButton)`
  background: ${(props) => props.theme.colors.danger};
  border-color: ${(props) => props.theme.colors.danger};

  &:hover {
    background: ${(props) => lighten(0.1, props.theme.colors.danger)};
    border-color: ${(props) => lighten(0.1, props.theme.colors.danger)};
  }
`;

const TransparentButton = styled(PrimaryButton)`
  background: transparent;
  border-color: transparent;

  &:hover {
    background: #ffffff11;
  }
`;

const ButtonContent = styled.div<{ $iconLayout?: IconLayout }>`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  flex-direction: ${(props) => (props.$iconLayout === 'end' ? 'row-reverse' : 'row')};
  column-gap: 0.5em;
  height: 1em;
`;

const TextButtonContent = (
  props: { icon?: IconType; iconLayout?: IconLayout; isLoading?: boolean } & PropsWithChildren
) => {
  return (
    <ButtonContent $iconLayout={props.iconLayout}>
      {props.isLoading && <CgSpinner className="animate-spin" />}
      {props.icon && <Icon IconType={props.icon} size={'l'} />}
      {props.children}
    </ButtonContent>
  );
};

export const Button = (props: TextButtonProps) => {
  const { variant, isLoading, icon, iconLayout, children, ...buttonProps } = props;
  const contentProps = { icon, iconLayout, isLoading, children };

  if (variant === 'accent') {
    return (
      <AccentedButton {...buttonProps}>
        <TextButtonContent {...contentProps} />
      </AccentedButton>
    );
  } else if (variant === 'danger') {
    return (
      <DangerButton {...buttonProps}>
        <TextButtonContent {...contentProps} />
      </DangerButton>
    );
  } else if (variant === 'transparent') {
    return (
      <TransparentButton {...buttonProps}>
        <TextButtonContent {...contentProps} />
      </TransparentButton>
    );
  }

  return (
    <PrimaryButton {...buttonProps}>
      <TextButtonContent {...contentProps} />
    </PrimaryButton>
  );
};
