import { useCallback } from 'react';
import { Message, useToaster as useRsuiteToaster } from 'rsuite';
import { TypeAttributes } from 'rsuite/esm/internals/types';
import { ToastContainerProps } from 'rsuite/esm/toaster/ToastContainer';

export function useToaster() {
  const toaster = useRsuiteToaster();

  const push = useCallback(
    ({
      message,
      showIcon,
      type,
      options
    }: {
      message: string;
      showIcon?: boolean;
      type?: TypeAttributes.Status;
      options?: ToastContainerProps;
    }) => {
      toaster.push(
        <Message showIcon={showIcon ?? true} type={type ?? 'info'}>
          <span data-testid="toast-message">{message}</span>
        </Message>,
        { placement: 'topCenter', duration: 5000, ...options }
      );
    },
    [toaster]
  );

  const info = useCallback((message: string) => push({ message, type: 'info' }), [push]);

  const success = useCallback((message: string) => push({ message, type: 'success' }), [push]);

  const warning = useCallback((message: string) => push({ message, type: 'warning' }), [push]);

  const error = useCallback((message: string) => push({ message, type: 'error' }), [push]);

  return { push, clear: toaster.clear, info, success, warning, error };
}
