import { useContext } from 'react';
import Env from '../Env.ts';
import { AuthenticatedUserContext } from './AuthenticatedUserContext.ts';

export const localDevelopmentBaseUri = 'http://localhost:5173';

const logoutRedirectQueryString = Env.IS_DEV ? `?redirect_uri=${encodeURIComponent(localDevelopmentBaseUri)}` : '';

export const redirectToLogout = () => {
  window.location.replace(`/api/logout${logoutRedirectQueryString}`);
};

export const useAuthentication = () => {
  return useContext(AuthenticatedUserContext);
};
