import { useCallback, useEffect, useMemo, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetUsersQuery, User } from '../../api';
import { getUsers as getAdminViewUsers, setUsers } from '../../store/slices/adminView';

export function useSearchUsers({ searchTerm }: { searchTerm?: string }): {
  users: User[];
  isLoading: boolean;
} {
  const dispatch = useDispatch();
  const users = useSelector(getAdminViewUsers());
  const [isLoading, setIsLoading] = useState(true);
  const [getUsers] = useLazyGetUsersQuery();
  const { showBoundary } = useErrorBoundary();

  const fetchAllUsers = useCallback(async () => {
    setIsLoading(true);

    let allUsers: User[] = [];
    let pageToken = undefined;

    try {
      do {
        const preferCachedValue = true;
        const { data } = await getUsers({ pageToken }, preferCachedValue);
        if (data) {
          allUsers = [...allUsers, ...data.items];
          pageToken = data.next;
        }
      } while (pageToken);

      dispatch(setUsers(allUsers));
    } catch (error) {
      showBoundary(error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, getUsers, showBoundary]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  const filteredUsers = useMemo(() => {
    if (!searchTerm) {
      return users;
    }

    const iSearchTerm = searchTerm.toLowerCase();
    return users.filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      return fullName.includes(iSearchTerm);
    });
  }, [users, searchTerm]);

  return { users: filteredUsers, isLoading };
}
