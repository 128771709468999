import { Bar, BarChart, CartesianGrid, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { LayoutType, Margin } from 'recharts/types/util/types';
import { chartColours } from '../../constants';
import { cn } from '../../util';
import { ChartTooltip } from './ChartTooltip';

type ActivityTypeBarChartProps = {
  data: Array<Record<string, unknown>>;
  dataKey?: string;
  labelKey?: string;
  margin?: Margin;
  layout?: LayoutType;
  animate?: boolean;
  className?: string;
};

export function ActivityTypeBarChart({
  data,
  dataKey,
  labelKey,
  margin,
  layout,
  animate,
  className
}: ActivityTypeBarChartProps) {
  return (
    <div className={cn('h-[300px]', className)}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          layout={layout ?? 'vertical'}
          margin={{ left: -16, right: 16, ...margin }}
          accessibilityLayer
        >
          <XAxis type="number" axisLine={false} tickLine={false} stroke="#fff" fontSize={10} />

          <YAxis
            dataKey={labelKey ?? 'label'}
            type="category"
            tickLine={false}
            fontSize={10}
            axisLine={false}
            stroke="#fff"
          />

          <CartesianGrid horizontal={false} strokeDasharray="3 3" strokeOpacity={0.2} stroke="#fff" />

          <Bar dataKey={dataKey ?? 'value'} radius={5} fill="#fff" isAnimationActive={!!animate}>
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={chartColours[index]} radius={0} />
            ))}

            <LabelList dataKey={dataKey ?? 'value'} position="right" offset={8} fontSize={14} stroke="#fff" />
          </Bar>

          <Tooltip content={<ChartTooltip />} cursor={{ fill: chartColours[0], fillOpacity: 0.1 }} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
