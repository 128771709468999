import styled from 'styled-components';
import { MdQuestionMark } from 'react-icons/md';
import { Icon } from './Icon.tsx';

interface TooltipProps {
  text: string;
}

const TooltipIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colours.primary};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;

  :hover {
    border-radius: 50%;
    background-color: ${(props) => props.theme.colours.subtle};
  }
`;

const TooltipIconContainer = styled(Icon)`
  padding: 4px;
`;

const TooltipInfo = styled.div`
  position: absolute;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  font-weight: ${(props) => props.theme.fontWeights.thin};
  font-size: ${(props) => props.theme.fontSizes.sm};
`;

const TooltipBox = styled.div`
  padding: 4px;
  position: relative;

  & ${TooltipIcon}:hover + ${TooltipInfo} {
    visibility: visible;
    margin-top: 20px;
    color: ${(props) => props.theme.colours.light};
    background-color: ${(props) => props.theme.colours.primary};
    width: max-content;
    border-radius: 8px;
    padding: 2px 8px;
    border: 2px solid ${(props) => props.theme.colours.outline};
  }
`;

export const Tooltip = ({ text }: TooltipProps) => {
  return (
    <TooltipBox>
      <TooltipIcon>
        <TooltipIconContainer size={'fillParent'} IconType={MdQuestionMark} />
      </TooltipIcon>
      <TooltipInfo>{text}</TooltipInfo>
    </TooltipBox>
  );
};
