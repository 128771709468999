import { useMemo } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { ActivityType, chartColours } from '../../constants';
import { cn } from '../../util';
import { ChartTooltip } from './ChartTooltip';

type ActivityTypePieChartProps = {
  data: Array<Record<string, unknown>>;
  dataKey?: string;
  labelKey?: string;
  includeAll?: boolean;
  className?: string;
};

export function ActivityTypePieChart({ data, dataKey, labelKey, includeAll, className }: ActivityTypePieChartProps) {
  const chartData = useMemo(
    () => data.filter(({ activityTypeId }) => includeAll || activityTypeId !== ActivityType.ALL),
    [data, includeAll]
  );

  const total = chartData.reduce((sum, entry) => sum + (Number(entry[dataKey ?? 'value']) || 0), 0);

  return (
    <div className={cn('pie-chart h-[400px]', className)}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie
            data={chartData}
            isAnimationActive={false}
            nameKey={labelKey ?? 'label'}
            dataKey={dataKey ?? 'value'}
            stroke="none"
            label={({ value }) => `${((value / total) * 100).toFixed(0)}%`}
            labelLine={false}
          >
            {chartData.map((_, index) => (
              <Cell key={`cell-${index}`} fill={chartColours[index]} />
            ))}
          </Pie>
          <Legend />
          <Tooltip content={<ChartTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
