import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TagPicker } from 'rsuite';
import { useDeletePatientMutation, useGetPatientActivityLogsQuery, useGetPatientQuery } from '../../../api';
import { Button } from '../../../components/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../../components/Card';
import { Heading } from '../../../components/Heading';
import { dateRanges } from '../../../components/inputs';
import { DateRangePickerInput } from '../../../components/inputs/DateRangePickerInput';
import { Modal } from '../../../components/Modal';
import { NoCapture } from '../../../components/NoCapture';
import { Pagination } from '../../../components/Pagination';
import { Spinner } from '../../../components/Spinner';
import { useListViewState } from '../../../components/table/listview/useListViewState';
import { activityTypeChoices, defaultPageSize } from '../../../constants';
import { ActivityAvgRPEChart } from '../../../dashboard/charts/ActivityAvgRPEChart';
import { ActivitySessionTimeSeriesChart } from '../../../dashboard/charts/ActivitySessionTimeSeriesChart';
import { ActivityTypeBarChart } from '../../../dashboard/charts/ActivityTypeBarChart';
import { ActivityTypePieChart } from '../../../dashboard/charts/ActivityTypePieChart';
import { ActivityTypeTotalRepsTimeSeriesChart } from '../../../dashboard/charts/ActivityTypeTotalRepsTimeSeriesChart';
import { useDashboardInsights } from '../../../dashboard/hooks/useDashboardInsights';
import { useGranularityFormatters } from '../../../dashboard/hooks/useGranularityFormatters';
import { usePatientActivitySummaryStats } from '../../../dashboard/hooks/usePatientActivitySummaryStats';
import { NoActivityDataIndicator } from '../../../dashboard/NoActivityDataIndicator';
import { useDateRange } from '../../../hooks/useDateRange';
import { NotFound } from '../../../NotFound';
import { ActivityLogContent } from './ActivityLogTile';
import { EditPatientForm } from './EditPatientForm';
import { PatientDetailsSkeleton } from './PatientDetailsSkeleton';
import { PatientTopBar } from './PatientTopBar';
import { SummaryStatistics } from './SummaryStatistics';

export const PatientDetails = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(dateRanges.last30days.value);
  const [activityTypeIds, setActivityTypeIds] = useState<string[]>([]);

  const navigate = useNavigate();
  const { id } = useParams();

  const [deletePatient, { isLoading: isDeleteLoading, isSuccess: isDeleteSuccess }] = useDeletePatientMutation();

  const {
    data: patient,
    isLoading: isPatientLoading,
    error
  } = useGetPatientQuery(id as string, { skip: isDeleteSuccess || isDeleteLoading });

  const {
    data: summaryStats,
    isLoading: isSummaryLoading,
    isFetching: isSummaryFetching
  } = usePatientActivitySummaryStats({
    patientIds: [Number(id)],
    activityTypeIds,
    dateRange
  });

  const {
    activitySessionTimeSeriesData,
    activityTypeTotalRepsTimeSeriesData,
    activityTimeSeriesData,
    activityTypeData,
    granularity,
    isLoading: isInsightsLoading
  } = useDashboardInsights({
    patientIds: [Number(id)],
    dateRange,
    activityTypeIds
  });

  const { tickFormatter, titleFormatter } = useGranularityFormatters({ granularity });

  const pageSize = defaultPageSize;
  const [{ page }, _, setListViewPage] = useListViewState({ page: 1 }, 'patientActivityLogList');

  const { dateFrom, dateTo } = useDateRange(dateRange);

  const { data: activityLogQueryResult, isLoading: isActivityLogsLoading } = useGetPatientActivityLogsQuery({
    patientId: id as string,
    activityTypeIds,
    dateFrom,
    dateTo,
    page: page || 1,
    pageSize: pageSize
  });

  const isLoading = isPatientLoading || isSummaryLoading || isActivityLogsLoading || isInsightsLoading;

  const totalPages = Math.ceil((activityLogQueryResult?.totalPatientActivityLogs || 0) / pageSize);

  const hasActivityData = useMemo(() => {
    return Object.values(summaryStats || {}).some((stat) => stat?.value !== 0);
  }, [summaryStats]);

  const removePatient = useCallback(async () => {
    if (id) {
      await deletePatient(id);
      navigate('/patients');
    }
  }, [id, deletePatient, navigate]);

  if (error && 'status' in error && error.status === 404) {
    return <NotFound />;
  }

  if (isLoading || !patient || !summaryStats) {
    return <PatientDetailsSkeleton />;
  }

  return (
    <div className="flex-1 flex-col">
      <PatientTopBar setShowEditModal={setShowEditModal} setShowDeleteModal={setShowDeleteModal} />

      <div className="flex items-center justify-between pt-6">
        <div>
          <Heading size="l">
            <NoCapture>{patient?.alias}</NoCapture>
          </Heading>

          {patient.createdAt && (
            <div className="ps-0.5 text-sm">Created {new Date(patient.createdAt).toLocaleDateString()}</div>
          )}
        </div>

        <div>
          <TagPicker
            data={activityTypeChoices}
            onChange={setActivityTypeIds}
            placeholder="All activities"
            size="lg"
            searchable={false}
            className="me-4 w-[350px]"
            data-testid="activity-type-input"
          />

          <DateRangePickerInput
            value={dateRange}
            onChange={setDateRange}
            className="w-[350px]"
            testId="date-range-input"
          />
        </div>
      </div>

      {!hasActivityData && <NoActivityDataIndicator className="mt-32" />}

      {hasActivityData && (
        <>
          <SummaryStatistics summaryStats={summaryStats} isLoading={isSummaryFetching} />
          <div className="flex justify-center gap-5 pb-5">
            <Card className="w-2/3">
              <CardHeader>
                <CardTitle>{titleFormatter('Session repetitions')}</CardTitle>
                <CardDescription>The total number of repetitions completed per session</CardDescription>
              </CardHeader>
              <CardContent>
                <ActivitySessionTimeSeriesChart
                  data={activitySessionTimeSeriesData}
                  dataKey="totalReps"
                  tickFormatter={tickFormatter}
                />
              </CardContent>
            </Card>

            <Card className="w-1/3">
              <CardHeader>
                <CardTitle>Avg repetitions by activity</CardTitle>
                <CardDescription>The average number of repetitions in an activity</CardDescription>
              </CardHeader>
              <CardContent>
                <ActivityTypeBarChart data={activityTypeData} dataKey="avgTotalReps" />
              </CardContent>
            </Card>
          </div>

          <div className="flex justify-center gap-5 pb-5">
            <Card className="w-2/3">
              <CardHeader>
                <CardTitle>{titleFormatter('Session time on task')}</CardTitle>
                <CardDescription>The total duration of all activies in seconds per session</CardDescription>
              </CardHeader>
              <CardContent>
                <ActivitySessionTimeSeriesChart
                  data={activitySessionTimeSeriesData}
                  dataKey="totalTimeTaken"
                  tickFormatter={tickFormatter}
                />
              </CardContent>
            </Card>

            <Card className="w-1/3">
              <CardHeader>
                <CardTitle>Avg time on task by activity</CardTitle>
                <CardDescription>The average duration of an activity in seconds</CardDescription>
              </CardHeader>
              <CardContent>
                <ActivityTypeBarChart data={activityTypeData} dataKey="avgTimeTaken" />
              </CardContent>
            </Card>
          </div>

          <div className="flex justify-center gap-5 pb-5">
            <Card className="w-2/3">
              <CardHeader>
                <CardTitle>{titleFormatter('Avg RPE')}</CardTitle>
                <CardDescription>The average rate of perceived exertion</CardDescription>
              </CardHeader>
              <CardContent>
                <ActivityAvgRPEChart
                  data={activityTimeSeriesData}
                  dataKey="avgFeedbackScore"
                  labelKey="timestamp"
                  tickFormatter={tickFormatter}
                />
              </CardContent>
            </Card>

            <Card className="w-1/3">
              <CardHeader>
                <CardTitle>Avg RPE by activity</CardTitle>
                <CardDescription>The average rate of perceived exertion for an activity</CardDescription>
              </CardHeader>
              <CardContent>
                <ActivityTypeBarChart data={activityTypeData} dataKey="avgFeedbackScore" />
              </CardContent>
            </Card>
          </div>

          <div className="flex justify-center gap-5 pb-5">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>{titleFormatter('Repetitions by activity')}</CardTitle>
                <CardDescription>The total number of repetitions for each activity</CardDescription>
              </CardHeader>
              <CardContent>
                <ActivityTypeTotalRepsTimeSeriesChart
                  data={activityTypeTotalRepsTimeSeriesData}
                  tickFormatter={tickFormatter}
                />
              </CardContent>
            </Card>
          </div>

          <div className="flex justify-center gap-5 pb-5">
            <Card className="w-full">
              <CardHeader>
                <CardTitle>Activities by activity</CardTitle>
                <CardDescription>The total number of activites for each activity type</CardDescription>
              </CardHeader>
              <CardContent>
                <ActivityTypePieChart data={activityTypeData} dataKey="activityCount" />
              </CardContent>
            </Card>

            <Card className="w-full">
              <CardHeader>
                <CardTitle>Repetitions by activity</CardTitle>
                <CardDescription>The total number of repetitions for each activity type</CardDescription>
              </CardHeader>
              <CardContent>
                <ActivityTypePieChart data={activityTypeData} dataKey="totalReps" />
              </CardContent>
            </Card>
          </div>

          <div className="flex justify-center pb-5">
            {activityLogQueryResult && ActivityLogContent(activityLogQueryResult.patientActivityLogs)}
          </div>

          <div className="flex justify-center pb-5">
            {totalPages > 1 && (
              <Pagination currentPage={page || 1} totalPages={totalPages} changePage={setListViewPage} />
            )}
          </div>
        </>
      )}

      {showEditModal && (
        <Modal title="Edit a Patient" onClose={() => setShowEditModal(false)}>
          <EditPatientForm oldPatient={patient} closeModal={() => setShowEditModal(false)} />
        </Modal>
      )}

      {showDeleteModal && (
        <Modal title="Delete a Patient" onClose={() => setShowDeleteModal(false)}>
          <div className="p-4">
            <div className="mb-5">Are you sure you want to delete this patient?</div>
            <div className="flex">
              <Button variant="accent" onClick={removePatient} disabled={isDeleteLoading}>
                Delete
              </Button>
              {isDeleteLoading && <Spinner />}
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
