import { FallbackProps } from 'react-error-boundary';
import { Button } from './button';
import { Heading } from './Heading';

export const ErrorBoundaryContent = ({ resetErrorBoundary }: FallbackProps) => {
  const heading = 'Sorry! Something went wrong.';
  const message = 'Please try again. If the problem persists, contact Bioliberty support.';

  return (
    <div className="flex flex-col items-center justify-center py-4" data-testid="error-boundary">
      <Heading size="l">{heading}</Heading>
      <p className="mb-4">{message}</p>
      <Button variant="primary" onClick={resetErrorBoundary}>
        Retry
      </Button>
    </div>
  );
};
