import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'styled-components';
import { FeatureFlag, getFeatureFlags, toggleFeatureFlag } from '../store/slices/featureFlags.ts';
import { Modal } from './Modal.tsx';

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const FeatureFlagList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 0.5rem;
`;

const FeatureFlagListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const FeatureFlagLabel = styled.label`
  margin-inline-start: 0.5rem;
`;

export function FeatureFlagModal() {
  const [isOpen, setIsOpen] = useState(false);
  const featureFlags = useSelector(getFeatureFlags());
  const dispatch = useDispatch();

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.ctrlKey && (event.key === '`' || event.key === '0')) {
        event.preventDefault();
        setIsOpen((prev) => !prev);
      }
    };

    window.addEventListener('keydown', handleKeydown);

    return () => window.removeEventListener('keydown', handleKeydown);
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <Modal title="Feature Flags" onClose={() => setIsOpen(false)}>
      <ModalContainer>
        <div>
          Toggle application features on and off during development. This modal is unavailable in production and any
          changes are only saved to this browser&apos;s session storage.
        </div>

        <FeatureFlagList>
          {Object.entries(featureFlags).map(([key, { isEnabled, label }]) => (
            <FeatureFlagListItem key={key}>
              <input
                type="checkbox"
                checked={isEnabled}
                name={`${key}-toggle`}
                onChange={() => dispatch(toggleFeatureFlag({ flag: key as FeatureFlag }))}
              />
              <FeatureFlagLabel htmlFor={`${key}-toggle`}>{label}</FeatureFlagLabel>
            </FeatureFlagListItem>
          ))}
        </FeatureFlagList>
      </ModalContainer>
    </Modal>
  );
}
