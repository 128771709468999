import { ReactNode } from 'react';
import { styled } from 'styled-components';
import { Heading } from './Heading.tsx';

interface TileProps {
  title: string;
  titleSize?: 'xsm' | 'sm' | 'm' | 'l';
  children: ReactNode;
  className?: string;
}

const TileContainer = styled.div`
  background-color: ${(props) => props.theme.layout.background};
  filter: brightness(110%);
  border: 1px solid ${(props) => props.theme.colors.highlight};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  overflow-y: auto;
`;

export const Tile = ({ title, titleSize, children, className }: TileProps) => {
  return (
    <TileContainer className={className}>
      <Heading size={titleSize || 'm'}>{title}</Heading>
      <ContentContainer>{children}</ContentContainer>
    </TileContainer>
  );
};
