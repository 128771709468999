import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../api';
import { RESET_ALL_ACTION } from '../actions.ts';
import { RootState } from '../index.ts';

export type AdminViewState = {
  users: User[];
};

const initialState: AdminViewState = {
  users: []
};

const slice = createSlice({
  name: 'adminView',
  initialState,
  extraReducers: (builder) => builder.addCase(RESET_ALL_ACTION, () => initialState),
  reducers: {
    setUsers: (state, { payload: users }: PayloadAction<User[]>) => {
      state.users = users;
    },
    updateUser: (state, { payload: { id, props } }: PayloadAction<{ id: string; props: Partial<User> }>) => {
      state.users = state.users.map((user) => {
        if (user.id === id) {
          return { ...user, ...props };
        }
        return user;
      });
    }
  }
});

export default slice.reducer;

export const { setUsers, updateUser } = slice.actions;

export const getUsers =
  () =>
  (state: RootState): User[] =>
    state.adminView.users;
