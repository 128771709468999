import { useCallback } from 'react';
import { ActivityTimeSeriesGranularity } from '../../api/insights';

export function useGranularityFormatters({ granularity }: { granularity: ActivityTimeSeriesGranularity }) {
  const tickFormatter = useCallback(
    (value: string) => {
      const date = new Date(value);

      if (granularity === 'hour') {
        return date.toLocaleTimeString('en-US', { hour: 'numeric' });
      }

      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    },
    [granularity]
  );

  const titleFormatter = useCallback(
    (value: string) => {
      switch (granularity) {
        case 'hour':
          return `${value} (hourly)`;
        case 'day':
          return `${value} (daily)`;
        default:
          return `${value} (weekly)`;
      }
    },
    [granularity]
  );

  return { tickFormatter, titleFormatter };
}
