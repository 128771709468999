import { createGlobalStyle } from 'styled-components';
import './assets/fonts/maax/stylesheet.css';

export const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.1) transparent;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  :root {
    height: 100%;
    font-family: Maax, Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;

    color-scheme: light dark;
    color: ${(props) => props.theme.typography.colors.primary};
    background-color: ${(props) => props.theme.layout.background};

    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
  }

  :root, body, #root {
    height: 100%;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }

  .rs-picker-popup {
    color: ${(props) => props.theme.typography.colors.secondary};
  }

  .rs-btn-link, .rs-btn-close {
    color: ${(props) => props.theme.typography.colors.secondary};
  }
`;
