import { useNavigate } from 'react-router-dom';
import { Patient } from '../../api';
import { NoCapture } from '../../components/NoCapture';
import {
  ListView,
  ListViewColumnDefinition,
  ListViewFieldProps,
  ListViewRow,
  ListViewSorting,
  rowsFromColumnDefinitions
} from '../../components/table/listview';

interface PatientListViewProps {
  patients: Patient[];
  loading: boolean;
  onSortingUpdated?: (sorting?: ListViewSorting) => void;
  sorting: ListViewSorting | undefined;
}

const columnDefinitions: ListViewColumnDefinition[] = [
  {
    field: 'alias',
    header: 'Unique Patient Identifier',
    width: '60%',
    fieldComponent: ({ value }) => <NoCapture>{value as string}</NoCapture>
  },
  {
    field: 'createdAt',
    header: 'Date Created',
    fieldComponent: ({ value }: ListViewFieldProps) => {
      return <>{new Date(value as string).toLocaleDateString()}</>;
    },
    width: '20%'
  },
  {
    field: 'lastActivityDate',
    header: 'Last Activity',
    fieldComponent: ({ value }: ListViewFieldProps) => {
      return <>{value ? new Date(value as string).toLocaleDateString() : ''}</>;
    },
    width: '20%'
  }
];

export const PatientListView = ({ patients, loading, onSortingUpdated, sorting }: PatientListViewProps) => {
  const navigate = useNavigate();
  const rows = rowsFromColumnDefinitions(patients, columnDefinitions);

  return (
    <div className="mt-5 h-[60vh] overflow-auto">
      <ListView
        rows={rows}
        columns={columnDefinitions}
        sorting={sorting}
        onSortingUpdated={onSortingUpdated}
        isLoading={loading}
        onItemSelected={(selected: ListViewRow) => navigate(`/patients/${(selected.data as Patient).id}`)}
        testId="patients-list"
      />
    </div>
  );
};
