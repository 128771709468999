import { FaArrowTrendDown, FaArrowTrendUp } from 'react-icons/fa6';
import { Skeleton } from '../../../components/Skeleton';
import { Tile } from '../../../components/Tile';
import { PatientActivitySummaryStats } from '../../../dashboard/hooks/usePatientActivitySummaryStats';
import { cn } from '../../../util';
import { formatSecondsString } from '../../../util/DateFormatter';

export const SummaryStatRow = ({ className, children }: React.PropsWithChildren & { className?: string }) => {
  return <div className={cn('flex gap-5 py-5', className)}>{children}</div>;
};

export const SummaryStatistics = ({
  summaryStats,
  isLoading
}: {
  summaryStats: PatientActivitySummaryStats;
  isLoading?: boolean;
}) => {
  return (
    <SummaryStatRow>
      <SummaryStatTile
        title="Avg repetitions per session"
        value={summaryStats.averageRepsPerSession?.value?.toFixed(2) ?? 'N/A'}
        prevValue={summaryStats.averageRepsPerSession?.prevValue?.toFixed(2) ?? 'N/A'}
        trend={summaryStats.averageRepsPerSession?.trend}
        isLoading={isLoading}
      />

      <SummaryStatTile
        title="Avg time on task per session "
        value={formatSecondsString(summaryStats.averageTimeTakenPerSession?.value ?? 0)}
        prevValue={formatSecondsString(summaryStats.averageTimeTakenPerSession?.prevValue ?? 0)}
        trend={summaryStats.averageTimeTakenPerSession?.trend}
        isLoading={isLoading}
      />

      <SummaryStatTile
        title="Avg activities per session"
        value={summaryStats.averageActivitiesPerSession?.value?.toFixed(2) ?? 'N/A'}
        prevValue={summaryStats.averageActivitiesPerSession?.prevValue?.toFixed(2) ?? 'N/A'}
        trend={summaryStats.averageActivitiesPerSession?.trend}
        isLoading={isLoading}
      />

      <SummaryStatTile
        title="Avg total repetitions"
        value={summaryStats.averageTotalRepsCompleted?.value?.toFixed(2) ?? 'N/A'}
        prevValue={summaryStats.averageTotalRepsCompleted?.prevValue?.toFixed(2) ?? 'N/A'}
        trend={summaryStats.averageTotalRepsCompleted?.trend}
        isLoading={isLoading}
      />

      <SummaryStatTile
        title="Avg total time on task"
        value={formatSecondsString(summaryStats.averageTotalTimeTaken?.value ?? 0)}
        prevValue={formatSecondsString(summaryStats.averageTotalTimeTaken?.prevValue ?? 0)}
        trend={summaryStats.averageTotalTimeTaken?.trend}
        isLoading={isLoading}
      />
    </SummaryStatRow>
  );
};

export const SummaryStatisticsSkeleton = () => {
  return (
    <SummaryStatRow className="h-[150px]">
      <Skeleton className="h-full w-full" />
      <Skeleton className="h-full w-full" />
      <Skeleton className="h-full w-full" />
      <Skeleton className="h-full w-full" />
      <Skeleton className="h-full w-full" />
    </SummaryStatRow>
  );
};

export const SummaryStatTrend = ({
  prevValue,
  trend,
  className
}: {
  prevValue: string;
  trend: number;
  className?: string;
}) => {
  if (trend === 0) {
    return (
      <div className={cn('text-xs', className)}>
        Unchanged <FaArrowTrendUp className={`inline text-xs`} />
      </div>
    );
  }

  const TrendIcon = trend > 0 ? FaArrowTrendUp : FaArrowTrendDown;
  const iconColor = trend > 0 ? 'text-green-400' : 'text-red-400';

  return (
    <div className={cn('text-xs', className)}>
      <TrendIcon className={`inline text-xs ${iconColor}`} /> {Math.abs(trend).toFixed(0)}% from {prevValue}
    </div>
  );
};

export const SummaryStatTile = ({
  title,
  value,
  prevValue,
  trend,
  isLoading,
  testId
}: {
  title: string;
  value: string;
  prevValue: string;
  trend?: number;
  isLoading?: boolean;
  testId?: string;
}) => {
  return (
    <div className="flex-1 flex-col" data-testid={testId ?? 'summary-stat-tile'}>
      <Tile title={title} titleSize={'sm'}>
        {isLoading && (
          <>
            <Skeleton className="mt-1 h-4 w-3/4" />
            <Skeleton className="mt-4 h-8 w-1/2" />
          </>
        )}
        {!isLoading && (
          <>
            {trend !== undefined && <SummaryStatTrend prevValue={prevValue} trend={trend} />}
            <div className="mt-4 text-3xl font-bold">{value}</div>
          </>
        )}
      </Tile>
    </div>
  );
};
