export const constants = {
  regExp: {
    // 2 to 50 alphabetical characters that may contain single quotes, hyphens, periods
    // or spaces as long as they are followed by one or more alphabetical characters
    findName: /^(?=.{1,50}$)[A-Za-z0-9]+(?:['\-.\s][A-Za-z0-9]+)*$/,
    email: /^[\w-.+]+@([\w-]+\.)+[\w-]{2,}$/,
    password:
      /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
  },
  roles: ['admin', 'clinician']
};

export enum ActivityType {
  EXT_MOB_CPM = 'f6a8c0f6-d853-47bd-80a1-ced80c12396a',
  EXT_MOB_ASSIST = '86b88aaa-6ab5-4fb8-8bc9-08ea3122d949',
  MANUAL_MODE = '6a84abb3-af06-4fe8-83bf-17bb8d407e33',
  GAME_MODE = '17c400cd-9c82-4118-86d5-0c9e30e6f38b',
  ALL = 'ALL'
}

export const activityLabelsById: Record<string, string> = {
  [ActivityType.EXT_MOB_ASSIST]: 'Active Assist',
  [ActivityType.EXT_MOB_CPM]: 'CPM',
  [ActivityType.GAME_MODE]: 'Game',
  [ActivityType.MANUAL_MODE]: 'Manual Mode',
  [ActivityType.ALL]: 'All'
};

export const activityTypeChoices = Object.entries(activityLabelsById)
  .map(([value, label]) => ({ label, value }))
  .filter(({ value }) => value !== ActivityType.ALL);

export enum Game {
  COSMIC_SWARM = 'cosmic_swarm',
  FORTS_AND_CASTLES = 'forts_and_castles',
  ZOMBIE_SLAY = 'zombie_slay',
  DEV = 'bioliberty:dev-game',
  DEMO = 'bioliberty:demo-game'
}

export const devOnlyGames: string[] = [Game.DEV, Game.DEMO];

export const gameLabelsById: Record<string, string> = {
  [Game.COSMIC_SWARM]: 'Cosmic Swarm',
  [Game.FORTS_AND_CASTLES]: 'Forts & Castles',
  [Game.ZOMBIE_SLAY]: 'Zombie Slay',
  [Game.DEV]: 'Dev',
  [Game.DEMO]: 'Demo'
};

export const defaultPageSize = 20;

export const chartColours = [
  '#2949f6',
  '#5a22a0',
  '#52817e',
  '#bd2c73',
  '#75471c',
  '#64992e',
  '#dba93a',
  '#6cc8c3',
  '#fc85ae'
];

export enum Route {
  Authorize = '/api/authorize',
  Home = '/',
  SignIn = '/sign-in',
  SignInCallback = '/sign-in/callback'
}
