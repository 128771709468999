import { SyntheticEvent } from 'react';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/DateRangePicker';
import { dateRangeChoices } from '.';

const { afterToday } = DateRangePicker;

type DateRangePickerInputProps = {
  value: [Date, Date] | null;
  onChange: (value: DateRange | null, event: SyntheticEvent<Element, Event>) => void;
  className?: string;
  testId?: string;
};

export const DateRangePickerInput = (props: DateRangePickerInputProps) => {
  return (
    <DateRangePicker
      value={props.value}
      onChange={props.onChange}
      showOneCalendar
      size={'lg'}
      placeholder={'All time'}
      format={'MMM dd, yyyy'}
      shouldDisableDate={afterToday()}
      className={props.className}
      ranges={dateRangeChoices}
      data-testid={props.testId}
    />
  );
};
