import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useVerifyEmailMutation } from '../../api/auth';
import { Spinner } from '../../components/Spinner';
import { Route } from '../../constants';
import { useToaster } from '../../hooks/useToaster';

export function VerifyEmail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const toaster = useToaster();
  const [verifyEmail] = useVerifyEmailMutation();
  const triggered = useRef(false);

  const onSuccess = useCallback(() => {
    toaster.success('Your email address has been verified and updated.');
    return navigate({ pathname: Route.Home }, { replace: true });
  }, [navigate, toaster]);

  const onError = useCallback(
    (msg = 'Something went wrong verifying your email address. Please try again.') => {
      toaster.error(msg);
      return navigate({ pathname: Route.Home }, { replace: true });
    },
    [navigate, toaster]
  );

  useEffect(() => {
    async function verify() {
      if (triggered.current) {
        return;
      }

      triggered.current = true;

      const code = searchParams.get('code');
      const email = searchParams.get('email');
      if (!code || !email) {
        return onError('The email verification link is malformed');
      }

      const { error } = await verifyEmail({ code, email });
      if (error) {
        return onError();
      }

      return onSuccess();
    }

    verify();
  }, [navigate, searchParams, verifyEmail, toaster, onSuccess, onError]);

  return (
    <div className="flex h-full w-full items-center justify-center">
      <Spinner />
    </div>
  );
}
