import { useEffect, useMemo } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useGetPatientActivitySummaryStatisticsQuery } from '../../api/insights';
import { useDateRange } from '../../hooks/useDateRange';
import { calculatePercentageChange } from '../../util';

interface PatientActivitySummaryStat {
  value?: number;
  prevValue?: number;
  trend?: number;
}

export interface PatientActivitySummaryStats {
  [index: string]: PatientActivitySummaryStat | undefined;

  averageActivitiesPerSession: PatientActivitySummaryStat;
  averageRepsPerSession: PatientActivitySummaryStat;
  averageTimeTakenPerSession: PatientActivitySummaryStat;
  averageTotalRepsCompleted: PatientActivitySummaryStat;
  averageTotalTimeTaken: PatientActivitySummaryStat;
}

export function usePatientActivitySummaryStats({
  patientIds,
  activityTypeIds,
  dateRange
}: {
  patientIds?: number[];
  activityTypeIds?: string[];
  dateRange?: [Date, Date] | null;
}) {
  const { dateFrom, dateTo, prevRangeDateFrom, prevRangeDateTo } = useDateRange(dateRange);

  const { showBoundary } = useErrorBoundary();

  const {
    data: summaryStats,
    isLoading,
    isFetching,
    error
  } = useGetPatientActivitySummaryStatisticsQuery({
    patientIds,
    activityTypeIds,
    dateFrom,
    dateTo
  });

  useEffect(() => error && showBoundary(error), [error, showBoundary]);

  const {
    data: prevSummaryStats,
    isLoading: isPrevSummaryStatsLoading,
    isFetching: isPrevSummaryStatsFetching,
    error: prevError
  } = useGetPatientActivitySummaryStatisticsQuery({
    patientIds,
    activityTypeIds,
    dateFrom: prevRangeDateFrom,
    dateTo: prevRangeDateTo
  });

  useEffect(() => prevError && showBoundary(prevError), [prevError, showBoundary]);

  const data = useMemo(() => {
    if (!summaryStats) {
      return undefined;
    }

    return Object.entries(summaryStats).reduce((acc, [key, value]) => {
      const prevValue = prevSummaryStats?.[key];
      const hasPrevRange = prevRangeDateFrom !== dateFrom;
      const trend =
        hasPrevRange && value !== undefined && prevValue !== undefined
          ? calculatePercentageChange(value, prevValue)
          : undefined;
      acc[key] = { value, prevValue, trend };
      return acc;
    }, {} as PatientActivitySummaryStats);
  }, [summaryStats, prevSummaryStats, prevRangeDateFrom, dateFrom]);

  return {
    data,
    isLoading: isLoading || isPrevSummaryStatsLoading,
    isFetching: isFetching || isPrevSummaryStatsFetching
  };
}
