import noActivityDataImage from '../assets/illustrations/no-activity-data.svg';
import { cn } from '../util';

export function NoActivityDataIndicator({ className }: { className?: string }) {
  return (
    <div
      className={cn('flex h-full flex-col items-center justify-center', className)}
      data-testid="no-activity-data-indicator"
    >
      <img src={noActivityDataImage} alt="No activity available" className="h-[300px]" />
      <div className="text-2xl">No activity available for the selected time period</div>
    </div>
  );
}
