import { useEffect, useState } from 'react';
import { TextInput } from '../../../components/inputs/TextInput.tsx';
import { Button } from '../../../components/button';
import { styled } from 'styled-components';
import { Patient, useUpdatePatientMutation } from '../../../api';
import { Spinner } from '../../../components/Spinner.tsx';
import { useValidator } from '../../../hooks/useValidator.ts';

type EditPatientFormProps = {
  oldPatient: Patient;
  closeModal: () => void;
};

const InputContainer = styled.div`
  margin-top: 20px;
  max-width: 400px;
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const PatientForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
`;

const FormErrorContainer = styled.div`
  margin-top: 20px;
`;

const LoadingContainer = styled.div`
  margin-left: 20px;
`;

export const EditPatientForm = ({ oldPatient, closeModal }: EditPatientFormProps) => {
  const [alias, setAlias] = useState(oldPatient.alias);
  const [formError, setFormError] = useState('');

  const { validator, validate } = useValidator({
    validAlias: {
      message: 'Please enter a valid unique patient identifier',
      rule: (val: string) => {
        return /\S+/.test(val);
      }
    }
  });

  const [updatePatient, { isLoading: isUpdating, isSuccess, error: updateError }] = useUpdatePatientMutation();

  const handleAliasChange = (e: string) => {
    setAlias(e);
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
    } else if (updateError) {
      if ('data' in updateError) {
        setFormError((updateError.data as { title: string }).title);
      } else {
        setFormError('Error updating patient');
      }
    }
  }, [isSuccess, updateError, closeModal]);

  const submit = async () => {
    if (validate()) {
      try {
        const updatedPatient = { id: oldPatient.id, alias: alias.trim() };
        await updatePatient(updatedPatient);
      } catch {
        setFormError('Error editing patient');
      }
    }
  };

  return (
    <PatientForm>
      <InputContainer>
        <TextInput
          initialValue={alias}
          label="Unique patient identifier*"
          placeholder="Enter unique patient identifier"
          onChange={handleAliasChange}
          errorMessage={validator.current.message('unique patient identifier', alias, 'required|validAlias')}
        />
      </InputContainer>
      <ButtonContainer>
        <Button variant={'accent'} onClick={() => submit()} disabled={isUpdating}>
          Update
        </Button>
        {isUpdating && (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        )}
      </ButtonContainer>
      {formError && <FormErrorContainer>{formError}</FormErrorContainer>} {/*Move this to toast or similar*/}
    </PatientForm>
  );
};
