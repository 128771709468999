import { useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { activityLabelsById, chartColours } from '../../constants';
import { cn } from '../../util';
import { ActivitySessionTimeSeriesDataPoint } from '../hooks/useDashboardInsights';
import { ChartTooltip } from './ChartTooltip';

export interface ActivitySessionTimeSeriesChart {
  data: Array<ActivitySessionTimeSeriesDataPoint>;
  dataKey: 'totalReps' | 'totalTimeTaken';
  tickFormatter?: (value: string, index: number) => string;
  className?: string;
}

export function ActivitySessionTimeSeriesChart({
  data,
  dataKey,
  tickFormatter,
  className
}: ActivitySessionTimeSeriesChart) {
  const transformedData = useMemo(() => {
    return data.map((item) => {
      const activities = Object.entries(item)
        .filter(([key]) => key !== 'timestamp')
        .map(([_key, value], index) => ({
          index: `Session ${index + 1}`,
          value: typeof value === 'object' ? value[dataKey] : 0
        }));

      return {
        timestamp: item.timestamp,
        ...Object.fromEntries(activities.map(({ index, value }) => [index, value]))
      };
    });
  }, [data, dataKey]);

  const stackKeys = useMemo(() => {
    const maxActivities = Math.max(
      ...transformedData.map((item) => Object.keys(item).filter((key) => key !== 'timestamp').length)
    );
    return Array.from({ length: maxActivities }, (_, i) => `Session ${i + 1}`);
  }, [transformedData]);

  return (
    <div className={cn('h-[300px]', className)}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={transformedData} layout="horizontal" margin={{ top: 24, right: 40 }} accessibilityLayer>
          <XAxis
            dataKey="timestamp"
            type="category"
            tickLine={false}
            fontSize={12}
            axisLine={false}
            stroke="#fff"
            minTickGap={12}
            interval="preserveStartEnd"
            tickMargin={12}
            tickFormatter={tickFormatter}
          />

          <YAxis type="number" axisLine={false} tickLine={false} stroke="#fff" fontSize={12} width={40} />

          <CartesianGrid vertical={false} strokeDasharray="3 3" strokeOpacity={0.2} stroke="#fff" />

          {stackKeys.map((stackKey, index) => (
            <Bar
              stackId="patientSessionId"
              key={stackKey}
              dataKey={stackKey}
              name={activityLabelsById[stackKey]}
              fill={chartColours[index % chartColours.length]}
              radius={0}
              isAnimationActive={false}
            />
          ))}

          <Tooltip
            content={<ChartTooltip formatter={(value) => tickFormatter?.(value, 0) ?? ''} stacked />}
            cursor={{ fill: chartColours[0], fillOpacity: 0.1 }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
