export function getRpeFill(rpe: number): string {
  let fill = '#6cc8c3';

  if (rpe >= 4 && rpe <= 6) {
    fill = '#64992e';
  } else if (rpe > 6 && rpe < 9) {
    fill = '#dba93a';
  } else if (rpe >= 9) {
    fill = '#bd2c73';
  }

  return fill;
}
