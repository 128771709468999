import { NavLink } from 'react-router-dom';
import { styled } from 'styled-components';
import bioliberty_logo from '../../assets/bioliberty_logo.png';
import { ConstrainedContent } from './ConstrainedContent.tsx';
import { Nav } from './Nav.tsx';
import { ProfileMenu } from './ProfileMenu.tsx';

const Header = styled.header`
  padding: 1.5rem 0;
  border-bottom: 1px solid #ffffff11;
`;

const HeaderContent = styled(ConstrainedContent)`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const Logo = styled.img`
  height: 2.25rem;
  margin-bottom: -0.5rem;
`;

const NavContainer = styled.div`
  flex: 1;
`;

export const AppHeader = () => {
  return (
    <Header>
      <HeaderContent>
        <NavLink to="/">
          <Logo src={bioliberty_logo} alt="Bioliberty Logo" />
        </NavLink>
        <NavContainer>
          <Nav />
        </NavContainer>
        <ProfileMenu />
      </HeaderContent>
    </Header>
  );
};
