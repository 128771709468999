import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';

export const FEATURE_FLAGS_STORAGE_KEY = 'featureFlags';

export type FeatureFlag = 'clinicianDashboard';

export type FeatureFlagState = { isEnabled: boolean; label: string };

export type FeatureFlagsState = Record<FeatureFlag, FeatureFlagState>;

const savedFlags = sessionStorage.getItem(FEATURE_FLAGS_STORAGE_KEY);

const initialState: FeatureFlagsState = {
  clinicianDashboard: {
    isEnabled: true,
    label: 'Clinician Dashboard'
  },
  ...(savedFlags ? JSON.parse(savedFlags) : {})
};

function toggleFeatureFlagReducer(
  state: FeatureFlagsState,
  { payload: { flag } }: PayloadAction<{ flag: FeatureFlag }>
): void {
  state[flag].isEnabled = !state[flag].isEnabled;
  sessionStorage.setItem(FEATURE_FLAGS_STORAGE_KEY, JSON.stringify(state));
}

const { actions, reducer } = createSlice({
  name: FEATURE_FLAGS_STORAGE_KEY,
  initialState,
  reducers: { toggleFeatureFlag: toggleFeatureFlagReducer }
});

export function getFeatureFlag(flag: FeatureFlag): (state: RootState) => FeatureFlagState {
  return ({ featureFlags }: RootState): FeatureFlagState => featureFlags[flag];
}

export function getFeatureFlags(): (state: RootState) => FeatureFlagsState {
  return ({ featureFlags }: RootState): FeatureFlagsState => featureFlags;
}

export const { toggleFeatureFlag } = actions;

export default reducer;
