import { cn } from '../../util';

export function Card({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        'bg-card bg-primary text-card-foreground border-highlight border-1 rounded-2xl shadow-sm brightness-110',
        className
      )}
      {...props}
    />
  );
}
