import { ChangeEvent, PropsWithChildren } from 'react';
import { Toggle } from 'rsuite';

export interface SwitchProps extends PropsWithChildren {
  checked: boolean;
  defaultChecked?: boolean;
  checkedChildren?: string;
  unCheckedChildren?: string;
  disabled?: boolean;
  onChange?: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  testId?: string;
}

export function Switch({ testId, children, ...props }: SwitchProps) {
  return (
    <Toggle data-testid={testId} {...props}>
      {children}
    </Toggle>
  );
}
