import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import Env from './Env.ts';
import { NotFound } from './NotFound';
import { Admin } from './admin';
import { RequireAuthentication } from './auth/RequireAuthentication.tsx';
import { SignIn, SignInCallback } from './auth/views/SignIn.tsx';
import { VerifyEmail } from './auth/views/VerifyEmail';
import { FeatureFlagModal } from './components/FeatureFlagModal.tsx';
import { Layout } from './components/layout';
import { Dashboard } from './dashboard/index.tsx';
import { Patients } from './patients';

import 'rsuite/DateRangePicker/styles/index.css';
import 'rsuite/Message/styles/index.css';
import 'rsuite/Tag/styles/index.css';
import 'rsuite/TagPicker/styles/index.css';
import 'rsuite/Toggle/styles/index.css';
import 'rsuite/toaster/styles/index.css';
import 'rsuite/useToaster/styles/index.css';

import './styles.css';

export const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<RequireAuthentication />}>
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            <Route path="/" element={<Dashboard />} />
            <Route path="/patients/*" element={<Patients />} />
            <Route path="/admin/*" element={<Admin />} />
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route path="/verify-email" element={<VerifyEmail />} />
        </Route>

        <Route path="sign-in" element={<SignIn />} />
        <Route path="sign-in/callback" element={<SignInCallback />} />
      </Route>
    )
  );

  return (
    <div className="flex h-full">
      <RouterProvider router={router} />
      {(Env.IS_DEV || Env.IS_STAGING) && <FeatureFlagModal />}
    </div>
  );
};
