import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserUpdate, useUpdateUserMutation } from '../../api';
import { useAuthentication } from '../../auth';
import { Button } from '../../components/button';
import { Select } from '../../components/inputs/Select.tsx';
import { ErrorMessage, InputLabel, TextInput } from '../../components/inputs/TextInput.tsx';
import { Modal } from '../../components/Modal';
import { Switch } from '../../components/Switch';
import { constants } from '../../constants.ts';
import { useToaster } from '../../hooks/useToaster';
import { useValidator } from '../../hooks/useValidator.ts';
import { updateUser as updateAdminViewUser } from '../../store/slices/adminView';

type EditUserModalProps = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
  onClose: () => void;
  enabled?: boolean;
};

export const EditUser = ({ id, email, firstName, lastName, roles, enabled, onClose }: EditUserModalProps) => {
  const { id: currentUserId } = useAuthentication();
  const [updateUser, { isLoading: isUpdating, error }] = useUpdateUserMutation();
  const dispatch = useDispatch();
  const toast = useToaster();

  const [userUpdate, setUserUpdate] = useState<UserUpdate>({
    id: id,
    firstName: firstName,
    lastName: lastName,
    roles: roles,
    enabled
  });

  const { validator, validate } = useValidator({
    validEmail: {
      message: 'Please enter a valid email address',
      rule: (val: string) => {
        return constants.regExp.email.test(val);
      }
    },
    validName: {
      message: 'Please enter a valid name',
      rule: (val: string) => {
        return constants.regExp.findName.test(val);
      }
    },
    validRole: {
      message: 'Please select a valid role',
      rule: (val: string[]) => {
        return val.length > 0 && val.every((role) => constants.roles.includes(role));
      }
    }
  });

  const isCurrentUser = useMemo(() => currentUserId === id, [currentUserId, id]);

  const onChange = useCallback(
    (property: string, value: string | string[] | boolean) => {
      setUserUpdate({ ...userUpdate, [property]: value });
    },
    [userUpdate]
  );

  const submit = useCallback(async () => {
    if (!validate()) {
      return;
    }

    console.log(enabled, userUpdate.enabled);

    await updateUser({
      ...userUpdate,
      enabled: userUpdate.enabled !== enabled ? userUpdate.enabled : undefined
    });

    if (error) {
      toast.error('Failed to update user. Please try again.');
      onClose();
    }

    dispatch(updateAdminViewUser({ id: userUpdate.id, props: userUpdate }));
    toast.success('User updated successfully.');
    onClose();
  }, [validate, updateUser, userUpdate, enabled, error, dispatch, toast, onClose]);

  return (
    <div className="flex flex-col justify-between px-2.5 pb-5" data-testid="edit-user-modal">
      <div className="text-xl">Personal Details</div>
      <div className="flex gap-5">
        <div className="mt-2.5 flex-1">
          <TextInput
            initialValue={email}
            label="Email address*"
            placeholder="Enter address"
            onChange={(value) => onChange('email', value)}
            disabled={true}
          />
        </div>
        <div className="mt-2.5 flex-1">
          <InputLabel htmlFor="input-field">Role*</InputLabel>
          <Select
            onChange={(value) => onChange('roles', [value.currentTarget.value])}
            value={userUpdate.roles.length > 0 ? userUpdate.roles[0] : 'clinician'}
            disabled={isCurrentUser && userUpdate.roles.length === 1 && userUpdate.roles[0] === 'admin'}
          >
            <option value="admin">Admin</option>
            <option value="clinician">Clinician</option>
          </Select>
          <ErrorMessage>{validator.current.message('roles', userUpdate.roles, 'required|validRole')}</ErrorMessage>
        </div>
      </div>
      <div className="flex gap-5">
        <div className="mt-2.5 flex-1">
          <TextInput
            initialValue={userUpdate.firstName}
            label="First name*"
            placeholder="Enter first name"
            onChange={(value) => onChange('firstName', value)}
            errorMessage={validator.current.message('firstName', userUpdate.firstName, 'required|validName')}
            testId="first-name-input"
          />
        </div>
        <div className="mt-2.5 flex-1">
          <TextInput
            initialValue={userUpdate.lastName}
            label="Last name*"
            placeholder="Enter last name"
            onChange={(value) => onChange('lastName', value)}
            errorMessage={validator.current.message('lastName', userUpdate.lastName, 'required|validName')}
            testId="last-name-input"
          />
        </div>
      </div>
      {!isCurrentUser && (
        <>
          <div className="mb-2.5 mt-10 text-xl">Authentication</div>
          <div className="flex flex-col gap-5">
            <div className="text-sm">A disabled user will not be able to sign in.</div>
            <Switch
              checked={!!userUpdate.enabled}
              onChange={(value) => onChange('enabled', value)}
              checkedChildren="Enabled"
              unCheckedChildren="Disabled"
              size="lg"
            />
          </div>
        </>
      )}
      <div className="mt-10 flex items-center gap-2">
        <Button
          variant="accent"
          onClick={() => submit()}
          disabled={isUpdating}
          isLoading={isUpdating}
          data-testid="edit-user-modal-save-btn"
        >
          Save
        </Button>
        <Button variant="transparent" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
};

export const EditUserModal = ({ onClose, ...props }: EditUserModalProps) => {
  const title = `Edit ${props.firstName} ${props.lastName}`;
  return (
    <Modal title={title} onClose={onClose}>
      <EditUser onClose={onClose} {...props} />
    </Modal>
  );
};
