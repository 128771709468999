import { Skeleton } from '../components/Skeleton';

export function PatientsChartsSkeleton() {
  return (
    <>
      <div className="flex flex-col gap-5 pb-5 md:flex-row">
        <Skeleton className="h-[425px] w-full" />
        <Skeleton className="h-[425px] w-full" />
      </div>
      <div className="flex flex-col gap-5 pb-5 md:flex-row">
        <Skeleton className="h-[425px] w-full" />
        <Skeleton className="h-[425px] w-full" />
      </div>
      <div className="flex flex-col gap-5 pb-5 md:flex-row">
        <Skeleton className="h-[425px] w-full" />
      </div>
      <div className="flex flex-col gap-5 pb-5 md:flex-row">
        <Skeleton className="h-[425px] w-full" />
        <Skeleton className="h-[425px] w-full" />
      </div>
      <div className="flex flex-col gap-5 pb-5 md:flex-row">
        <Skeleton className="h-[425px] w-full" />
      </div>
      <div className="mb-10 flex flex-col gap-5 md:flex-row">
        <Skeleton className="h-[425px] w-full" />
        <Skeleton className="h-[425px] w-full" />
        <Skeleton className="h-[425px] w-full" />
      </div>
    </>
  );
}
