import { useCallback, useMemo, useState } from 'react';
import { Profile, useUpdateUserMutation } from '../../api';
import { Button } from '../../components/button';
import { Heading } from '../../components/Heading.tsx';
import { TextInput } from '../../components/inputs/TextInput.tsx';
import { Modal } from '../../components/Modal.tsx';
import { useToaster } from '../../hooks/useToaster';
import { useValidator } from '../../hooks/useValidator';

interface UpdateEmailModalProps {
  user: Profile;
  onClose: () => void;
}

export const UpdateEmailModal = ({ user, onClose }: UpdateEmailModalProps) => {
  const [newEmail, setNewEmail] = useState('');
  const [newEmailConfirmation, setNewEmailConfirmation] = useState('');
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const toaster = useToaster();

  const validators = useMemo(
    () => ({
      newEmail: {
        message: 'The new email must be different to your current email',
        rule: () => newEmail !== user.email
      },
      emailConfirmation: {
        message: 'The email confirmation must match',
        rule: () => newEmail === newEmailConfirmation
      }
    }),
    [newEmail, newEmailConfirmation, user.email]
  );

  const { validator, validate } = useValidator(validators);

  const onUpdate = useCallback(async () => {
    if (!validate()) {
      return;
    }

    try {
      await updateUser({
        id: user.id,
        email: newEmail,
        firstName: user.givenName || user.name.split(' ')[0],
        lastName: user.familyName || user.name.split(' ')[1],
        roles: user.roles
      }).unwrap();

      onClose();

      toaster.success('Please check your new email address for a verification link.');
    } catch {
      toaster.error('Failed to update email. Please try again.');
    }
  }, [
    newEmail,
    onClose,
    toaster,
    updateUser,
    user.familyName,
    user.givenName,
    user.id,
    user.name,
    user.roles,
    validate
  ]);

  return (
    <Modal title="Update Email" onClose={onClose}>
      <div className="flex flex-col justify-between px-2.5 pb-5">
        <Heading size={'sm'}>An email with a verification link will be sent to the new email address.</Heading>

        <div className="mt-5 max-w-[400px]">
          <TextInput
            label="New Email*"
            onChange={(value) => setNewEmail(value)}
            errorMessage={validator.current.message('email', newEmail, 'required|email|newEmail')}
            autoFocus
          />
        </div>

        <div className="mt-5 max-w-[400px]">
          <TextInput
            label="Confirm New Email*"
            onChange={(value) => setNewEmailConfirmation(value)}
            errorMessage={validator.current.message(
              'newEmailConfirmation',
              newEmailConfirmation,
              'required|email|emailConfirmation'
            )}
          />
        </div>

        <div className="mt-10 flex items-center gap-4">
          <Button
            variant="accent"
            onClick={onUpdate}
            isLoading={isLoading}
            disabled={isLoading || !newEmail || !newEmailConfirmation}
          >
            Update
          </Button>

          <Button variant="transparent" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
