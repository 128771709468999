import { Modal } from '../../components/Modal.tsx';
import { ErrorMessage, PasswordInput, TextInput } from '../../components/inputs/TextInput.tsx';
import { styled } from 'styled-components';
import { useEffect, useState } from 'react';
import { useValidator } from '../../hooks/useValidator.ts';
import { constants } from '../../constants.ts';
import { Button } from '../../components/button';
import { Spinner } from '../../components/Spinner.tsx';
import { ConfirmResetPassword, useConfirmResetPasswordMutation, useResetPasswordMutation } from '../../api/auth.ts';
import { Heading } from '../../components/Heading.tsx';
import SimpleReactValidator from 'simple-react-validator';

type ResetPasswordModalProps = {
  userId: string;
  onClose: () => void;
};

type ResetPasswordFormProps = {
  onSubmit: () => void;
  isUpdating: boolean;
  formError: string;
};

type ChangePasswordFormProps = {
  onSubmit: () => void;
  isUpdating: boolean;
  formError: string;
  changePasswordRequest: ConfirmResetPassword;
  onChange: (property: string, value: string | string[]) => void;
  validator: React.MutableRefObject<SimpleReactValidator>;
};

const ResetPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px 20px 10px;
`;

const ChangePasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px 20px 10px;
`;

const InputContainer = styled.div`
  margin-top: 20px;
  max-width: 400px;
`;

const InputColumns = styled.div`
  display: flex;
  gap: 20px;

  > * {
    flex: 1;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const FormErrorContainer = styled.div`
  margin-top: 20px;
`;

const LoadingContainer = styled.div`
  margin-left: 20px;
`;

const ResetPasswordForm = ({ onSubmit, isUpdating, formError }: ResetPasswordFormProps) => (
  <ResetPasswordContainer>
    <Heading size={'sm'}>By clicking confirm, we will send an email to reset your password.</Heading>
    <ButtonContainer>
      <Button variant={'accent'} onClick={onSubmit} disabled={isUpdating}>
        Confirm
      </Button>
      {isUpdating && (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      )}
    </ButtonContainer>
    {formError && (
      <FormErrorContainer>
        <ErrorMessage>{formError}</ErrorMessage>
      </FormErrorContainer>
    )}
  </ResetPasswordContainer>
);

const ChangePasswordForm = (props: ChangePasswordFormProps) => (
  <ChangePasswordContainer>
    <Heading size={'sm'}>
      We have sent a password reset code to your email. Enter it below to reset your password.
    </Heading>
    <InputColumns>
      <InputContainer>
        <TextInput
          label="Confirmation code"
          placeholder="Enter confirmation code"
          onChange={(value) => props.onChange('confirmationCode', value)}
          errorMessage={props.validator.current.message(
            'confirmationCode',
            props.changePasswordRequest.confirmationCode,
            'required'
          )}
        />
      </InputContainer>
    </InputColumns>
    <InputColumns>
      <InputContainer>
        <PasswordInput
          label="New password"
          placeholder="Enter new password"
          onChange={(value) => props.onChange('password', value)}
          errorMessage={props.validator.current.message(
            'password',
            props.changePasswordRequest.password,
            'required|validPassword'
          )}
        />
      </InputContainer>
    </InputColumns>
    <InputColumns>
      <InputContainer>
        <PasswordInput
          label="Confirm new password"
          placeholder="Re-enter new password"
          onChange={(value) => props.onChange('passwordConfirmation', value)}
          errorMessage={props.validator.current.message(
            'passwordConfirmation',
            props.changePasswordRequest.passwordConfirmation,
            `required|in:${props.changePasswordRequest.password}`,
            { messages: { in: 'Passwords need to match' } }
          )}
        />
      </InputContainer>
    </InputColumns>
    <ButtonContainer>
      <Button variant={'accent'} onClick={props.onSubmit} disabled={props.isUpdating}>
        Change Password
      </Button>
      {props.isUpdating && (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      )}
    </ButtonContainer>
    {props.formError && (
      <FormErrorContainer>
        <ErrorMessage>{props.formError}</ErrorMessage>
      </FormErrorContainer>
    )}
  </ChangePasswordContainer>
);

export const ResetPasswordModal = ({ userId, onClose }: ResetPasswordModalProps) => {
  const [formError, setFormError] = useState('');
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);

  const [resetPassword, { isLoading: isUpdatingReset, error: errorReset, isSuccess: isSuccessReset }] =
    useResetPasswordMutation();

  const [changePassword, { isLoading: isUpdatingChange, error: errorChange, isSuccess: isSuccessChange }] =
    useConfirmResetPasswordMutation();

  const [changePasswordRequest, setChangePasswordRequest] = useState<ConfirmResetPassword>({
    userId: userId,
    confirmationCode: '',
    password: '',
    passwordConfirmation: ''
  });

  const { validator, validate } = useValidator({
    validPassword: {
      message:
        'Please enter a valid password with a minimum length of 8 characters, with one lowercase letter, one uppercase letter, one number, and one special character',
      rule: (val: string) => {
        return constants.regExp.password.test(val);
      }
    }
  });

  const onChange = (property: string, value: string | string[]) => {
    setChangePasswordRequest({ ...changePasswordRequest, [property]: value });
  };

  useEffect(() => {
    if (isSuccessReset) {
      setShowChangePasswordForm(true);
    } else if (errorReset) {
      setFormError('Error sending reset password email');
    }
  }, [isSuccessReset, errorReset]);

  useEffect(() => {
    if (isSuccessChange) {
      onClose();
    } else if (errorChange) {
      if ('data' in errorChange && typeof errorChange.data === 'string') {
        setFormError(errorChange.data);
      } else {
        setFormError('Error changing password');
      }
    }
  }, [isSuccessChange, errorChange, onClose]);

  const submitResetPassword = async () => {
    await resetPassword(userId);
  };

  const submitChangePassword = async () => {
    if (!validate()) {
      return;
    }

    await changePassword(changePasswordRequest);
  };

  return (
    <Modal title="Change Password" onClose={onClose}>
      {showChangePasswordForm ? (
        <ChangePasswordForm
          onSubmit={submitChangePassword}
          isUpdating={isUpdatingChange}
          formError={formError}
          changePasswordRequest={changePasswordRequest}
          onChange={onChange}
          validator={validator}
        />
      ) : (
        <ResetPasswordForm onSubmit={submitResetPassword} isUpdating={isUpdatingReset} formError={formError} />
      )}
    </Modal>
  );
};
