import posthog from 'posthog-js';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryContent } from './ErrorBoundaryContent';

export function ErrorBoundary({ children }: React.PropsWithChildren) {
  function onError(error: Error) {
    if (error instanceof Error) {
      console.error(error);
      posthog.captureException(error);
    }
  }

  return (
    <ReactErrorBoundary FallbackComponent={ErrorBoundaryContent} onError={onError}>
      {children}
    </ReactErrorBoundary>
  );
}
