import { useEffect } from 'react';
import { useDeleteUserMutation } from '../../api';
import { Button } from '../../components/button';
import { Modal } from '../../components/Modal.tsx';
import { Spinner } from '../../components/Spinner.tsx';

type DeleteUserModalProps = {
  userId: string;
  email: string;
  onClose: () => void;
};

const DeleteUser = ({ userId, email, onClose }: DeleteUserModalProps) => {
  const [deleteUser, { isLoading: isDeleting, isSuccess }] = useDeleteUserMutation();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  return (
    <div className="flex flex-col justify-between px-2.5 pb-5 pt-0">
      <div className="mb-5">Are you sure you want to delete user {email}?</div>
      <div className="flex">
        <Button variant={'accent'} onClick={() => deleteUser(userId)} disabled={isDeleting}>
          Delete
        </Button>
        {isDeleting && <Spinner />}
      </div>
    </div>
  );
};

export const DeleteUserModal = ({ onClose, ...props }: DeleteUserModalProps) => {
  return (
    <Modal title="Delete User" onClose={onClose}>
      <DeleteUser onClose={onClose} {...props} />
    </Modal>
  );
};
