import { endOfDay } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useGetInsightsDashboardQuery } from '../../api/insights';
import { ActivityType, devOnlyGames } from '../../constants';
import { useDateRange } from '../../hooks/useDateRange';
import { getActivityLabel, toUtcMidnight } from '../../util';

export function useDashboardInsights({
  patientIds,
  activityTypeIds,
  dateRange
}: {
  patientIds?: number[];
  activityTypeIds?: string[];
  dateRange?: [Date, Date] | null;
}) {
  const { dateFrom, dateTo, granularity } = useDateRange(dateRange);

  const { data, isLoading, isFetching, error } = useGetInsightsDashboardQuery({
    patientIds,
    activityTypeIds,
    dateFrom,
    dateTo,
    granularity
  });

  const { showBoundary } = useErrorBoundary();

  useEffect(() => error && showBoundary(error), [error, showBoundary]);

  const activityTypeData = useMemo(() => {
    const formattedData =
      data?.activityTypes
        .map((activity) => ({
          ...activity,
          avgTotalReps: Number(activity.avgTotalReps.toFixed(2)),
          avgFeedbackScore: Number(activity.avgFeedbackScore.toFixed(2)),
          avgTimeTaken: Number(activity.avgTimeTaken.toFixed(2)),
          label: getActivityLabel(activity)
        }))
        .filter(({ label, gameId }) => !!label && (!gameId || !devOnlyGames.includes(gameId))) ?? [];

    return formattedData.sort((a, b) => {
      if (b.activityTypeId === ActivityType.ALL) {
        return -1;
      }

      if (!a.gameId && b.gameId) {
        return -1;
      } else if (a.gameId && !b.gameId) {
        return 1;
      }

      return a.label.localeCompare(b.label);
    });
  }, [data]);

  const getTimeSeriesDataDateRange = useCallback(
    (timeSeriesData: Array<{ timestamp: string }>) => {
      const start = dateFrom ? new Date(dateFrom) : new Date(timeSeriesData[0]?.timestamp);
      const end = dateTo ? new Date(dateTo) : endOfDay(new Date());
      return [start, end];
    },
    [dateFrom, dateTo]
  );

  const activityTimeSeriesData = useMemo(() => {
    if (!data?.activityTimeSeries.data?.length || isLoading || isFetching) {
      return [];
    }

    const partialTimeSeriesData = data.activityTimeSeries.data.map((activityData) => {
      return {
        ...activityData,
        timestamp: new Date(activityData.timestamp).toISOString(),
        totalTimeTaken: Math.floor(activityData.totalTimeTaken / 60),
        avgFeedbackScore: Number(activityData.avgFeedbackScore.toFixed(1))
      };
    });

    const [startDate, endDate] = getTimeSeriesDataDateRange(partialTimeSeriesData);

    return fillMissingTimestamps({
      timeSeriesData: partialTimeSeriesData,
      startDate,
      endDate,
      granularity,
      defaultValues: {
        activityTypeId: null,
        patientSessionId: null,
        activePatientCount: 0,
        avgFeedbackScore: 0,
        sessionCount: 0,
        totalReps: 0,
        totalTimeTaken: 0
      }
    });
  }, [data?.activityTimeSeries.data, getTimeSeriesDataDateRange, granularity, isFetching, isLoading]);

  const activityTypeTotalRepsTimeSeriesData = useMemo(() => {
    if (!data?.activityTypeTimeSeries.data?.length || isLoading || isFetching) {
      return [];
    }

    const timeSeriesByTimestamp = data?.activityTypeTimeSeries.data.reduce(
      (acc: Record<string, ActivityTypeTimeSeriesDataPoint>, { timestamp, activityTypeId, totalReps }) => {
        if (!activityTypeId) {
          return acc;
        }

        if (!acc[timestamp]) {
          acc[timestamp] = {
            timestamp: new Date(timestamp).toISOString(),
            [activityTypeId]: totalReps
          };
        } else {
          acc[timestamp] = {
            ...acc[timestamp],
            [activityTypeId]: totalReps
          };
        }

        return acc;
      },
      {}
    );

    const partialTimeSeriesData = Object.values(timeSeriesByTimestamp);

    const [startDate, endDate] = getTimeSeriesDataDateRange(partialTimeSeriesData);

    return fillMissingTimestamps({
      timeSeriesData: partialTimeSeriesData,
      startDate,
      endDate,
      granularity,
      defaultValues: {}
    });
  }, [data?.activityTypeTimeSeries.data, getTimeSeriesDataDateRange, granularity, isFetching, isLoading]);

  const activitySessionTimeSeriesData = useMemo(() => {
    if (!data?.activitySessionTimeSeries.data?.length || isLoading || isFetching) {
      return [];
    }

    const timeSeriesByTimestamp = data?.activitySessionTimeSeries.data.reduce(
      (acc: Record<string, ActivitySessionTimeSeriesDataPoint>, { timestamp, patientSessionId, ...rest }) => {
        if (!patientSessionId) {
          return acc;
        }

        if (!acc[timestamp]) {
          acc[timestamp] = {
            timestamp: new Date(timestamp).toISOString(),
            [patientSessionId]: rest
          };
        } else {
          acc[timestamp] = {
            ...acc[timestamp],
            [patientSessionId]: rest
          };
        }

        return acc;
      },
      {}
    );

    const partialTimeSeriesData = Object.values(timeSeriesByTimestamp);

    const [startDate, endDate] = getTimeSeriesDataDateRange(partialTimeSeriesData);

    return fillMissingTimestamps({
      timeSeriesData: partialTimeSeriesData,
      startDate,
      endDate,
      granularity,
      defaultValues: {}
    });
  }, [data?.activitySessionTimeSeries.data, getTimeSeriesDataDateRange, granularity, isFetching, isLoading]);

  return {
    activityTypeData,
    activityTimeSeriesData,
    activitySessionTimeSeriesData,
    activityTypeTotalRepsTimeSeriesData,
    granularity,
    isLoading
  };
}

export interface TimeSeriesDataPoint {
  timestamp: string;
  [key: string]: unknown;
}

export interface ActivityTypeTimeSeriesDataPoint {
  timestamp: string;
  [activityTypeId: string]: string | number;
}

export interface ActivitySessionTimeSeriesDataPoint {
  timestamp: string;
  [patientSessionId: string]: string | { totalReps: number; totalTimeTaken: number };
}

const fillMissingTimestamps = <T extends TimeSeriesDataPoint>({
  timeSeriesData,
  startDate,
  endDate,
  granularity,
  defaultValues
}: {
  timeSeriesData: T[];
  startDate: Date;
  endDate: Date;
  granularity: 'hour' | 'day' | 'week';
  defaultValues: Omit<T, 'timestamp'>;
}): T[] => {
  const filledData: T[] = [];
  const dataByTimestamp = new Map(timeSeriesData.map((data) => [data.timestamp, data]));

  let start = new Date(startDate);
  const end = new Date(endDate);

  // The fill logic expects day/week timestamps to have no hours i.e. end with T00:00:00
  if (granularity !== 'hour') {
    start = toUtcMidnight(start);
  }

  while (start < end) {
    const timestamp = start.toISOString();
    const existingData = dataByTimestamp.get(timestamp);

    if (existingData) {
      filledData.push(existingData);
    } else {
      filledData.push({
        ...defaultValues,
        timestamp
      } as T);
    }

    switch (granularity) {
      case 'hour':
        start.setUTCHours(start.getUTCHours() + 1);
        break;
      case 'week':
        start.setUTCDate(start.getUTCDate() + 7);
        break;
      case 'day':
      default:
        start.setUTCDate(start.getUTCDate() + 1);
    }
  }

  return filledData;
};
