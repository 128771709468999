import { useEffect, useRef, useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';

export const useValidator = (customValidators = {}) => {
  const [validators, setValidators] = useState(customValidators);

  const validator = useRef(
    new SimpleReactValidator({
      // These are for extra custom validators that are not included by default
      // https://github.com/dockwa/simple-react-validator?tab=readme-ov-file#rules
      validators
    })
  );
  const [, setErrors] = useState({});

  useEffect(() => {
    if (JSON.stringify(validators) === JSON.stringify(customValidators)) {
      return;
    }

    validator.current = new SimpleReactValidator({
      validators: customValidators
    });
    setErrors({});
    setValidators(customValidators);
  }, [validators, customValidators]);

  const validate = () => {
    if (validator.current?.allValid()) {
      return true;
    } else {
      validator.current?.showMessages();
      setErrors(validator.current?.getErrorMessages() || {});
      return false;
    }
  };

  return { validator, validate };
};
