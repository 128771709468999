import { useMemo, useState } from 'react';
import { TagPicker } from 'rsuite';
import { Heading } from '../components/Heading';
import { dateRanges } from '../components/inputs';
import { DateRangePickerInput } from '../components/inputs/DateRangePickerInput';
import { activityTypeChoices } from '../constants';
import { SummaryStatistics, SummaryStatisticsSkeleton } from '../patients/views/details/SummaryStatistics';
import { useDashboardInsights } from './hooks/useDashboardInsights';
import { usePatientActivitySummaryStats } from './hooks/usePatientActivitySummaryStats';
import { NoActivityDataIndicator } from './NoActivityDataIndicator';
import { PatientsCharts } from './PatientsCharts';
import { PatientsChartsSkeleton } from './PatientsChartsSkeleton';

export function Dashboard() {
  const [activityTypeIds, setActivityTypeIds] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(dateRanges.last30days.value);

  const {
    data: summaryStats,
    isLoading: isSummaryStatsLoading,
    isFetching: isSummaryStatsFetching
  } = usePatientActivitySummaryStats({ dateRange, activityTypeIds });

  const {
    activityTypeData,
    activityTimeSeriesData,
    activityTypeTotalRepsTimeSeriesData,
    granularity,
    isLoading: isActivityTypeSummaryLoading
  } = useDashboardInsights({
    dateRange,
    activityTypeIds
  });

  const isLoading = isSummaryStatsLoading || isActivityTypeSummaryLoading || !summaryStats || !activityTypeData;

  const hasActivityData = useMemo(() => {
    return summaryStats && Object.values(summaryStats).some((stat) => stat?.value !== 0);
  }, [summaryStats]);

  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center justify-between">
        <Heading size="l">Summary Dashboard</Heading>
        <div>
          <TagPicker
            data={activityTypeChoices}
            onChange={setActivityTypeIds}
            placeholder="All activities"
            size="lg"
            searchable={false}
            className="me-4 w-[350px]"
            data-testid="activity-type-input"
          />

          <DateRangePickerInput
            value={dateRange}
            onChange={setDateRange}
            className="w-[350px]"
            testId="date-range-input"
          />
        </div>
      </div>

      {isLoading && (
        <div>
          <SummaryStatisticsSkeleton />
          <PatientsChartsSkeleton />
        </div>
      )}

      {!isLoading && hasActivityData && (
        <>
          <SummaryStatistics summaryStats={summaryStats} isLoading={isSummaryStatsFetching} />
          <PatientsCharts
            activityTypeData={activityTypeData}
            activityTimeSeriesData={activityTimeSeriesData}
            activityTypeTotalRepsTimeSeriesData={activityTypeTotalRepsTimeSeriesData}
            granularity={granularity}
          />
        </>
      )}

      {!isLoading && !hasActivityData && <NoActivityDataIndicator />}
    </div>
  );
}
