import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { activityLabelsById, ActivityType, chartColours } from '../../constants';
import { cn } from '../../util';
import { ActivityTypeTimeSeriesDataPoint } from '../hooks/useDashboardInsights';
import { ChartTooltip } from './ChartTooltip';

const stackKeys = Object.keys(activityLabelsById).filter((key) => key !== ActivityType.ALL);

export interface ActivityTypeTotalRepsTimeSeriesChart {
  data: Array<ActivityTypeTimeSeriesDataPoint>;
  tickFormatter?: (value: string, index: number) => string;
  className?: string;
}

export function ActivityTypeTotalRepsTimeSeriesChart({
  data,
  tickFormatter,
  className
}: ActivityTypeTotalRepsTimeSeriesChart) {
  return (
    <div className={cn('h-[300px]', className)}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} layout="horizontal" margin={{ top: 24, right: 40 }} accessibilityLayer>
          <XAxis
            dataKey="timestamp"
            type="category"
            tickLine={false}
            fontSize={12}
            axisLine={false}
            stroke="#fff"
            minTickGap={35}
            interval="preserveStartEnd"
            tickMargin={12}
            tickFormatter={tickFormatter}
          />

          <YAxis type="number" axisLine={false} tickLine={false} stroke="#fff" fontSize={12} width={40} />

          <CartesianGrid vertical={false} strokeDasharray="3 3" strokeOpacity={0.2} stroke="#fff" />

          {stackKeys.map((stackKey, index) => (
            <Bar
              stackId="activityTypes"
              key={stackKey}
              dataKey={stackKey}
              name={activityLabelsById[stackKey]}
              fill={chartColours[index]}
              isAnimationActive={false}
              radius={0}
            />
          ))}

          <Tooltip
            content={<ChartTooltip formatter={(value) => tickFormatter?.(value, 0) ?? ''} stacked />}
            cursor={{ fill: chartColours[0], fillOpacity: 0.1 }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
