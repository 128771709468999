import { styled } from 'styled-components';
import { FaChevronLeft } from 'react-icons/fa';
import { FaChevronRight } from 'react-icons/fa';
import { Icon } from './Icon.tsx';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  changePage: (page: number) => void;
}

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 20px;
`;

const PageNumber = styled.div<{ $isActive: boolean }>`
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  color: ${(props) => (props.$isActive ? props.theme.colours.accent : props.theme.colours.light)};
`;

const Dots = styled.div<{ $isActive: boolean }>`
  cursor: default;
  margin-left: 5px;
  margin-right: 5px;
  color: ${(props) => (props.$isActive ? props.theme.colours.accent : props.theme.colours.light)};
`;

const IconContainer = styled.div<{ $active: boolean }>`
  cursor: ${(props) => (props.$active ? 'pointer' : 'default')};
  margin-left: 20px;
  margin-right: 20px;
  color: ${(props) => !props.$active && 'grey'};
`;

export const Pagination = ({ currentPage, totalPages, changePage }: PaginationProps) => {
  const showAllNumbers = () => {
    return Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
      <PageNumber $isActive={pageNumber === currentPage} key={pageNumber} onClick={() => changePage(pageNumber)}>
        {pageNumber}
      </PageNumber>
    ));
  };

  const navRight = () => {
    changePage(currentPage + 1);
  };

  const navLeft = () => {
    changePage(currentPage - 1);
  };

  const showDotsRight = () => {
    return (
      <>
        {Array.from({ length: 3 }, (_, i) => i + 1).map((pageNumber) => (
          <PageNumber key={pageNumber} onClick={() => changePage(pageNumber)} $isActive={pageNumber === currentPage}>
            {pageNumber}
          </PageNumber>
        ))}
        <Dots $isActive={false}>...</Dots>
        <PageNumber onClick={() => changePage(totalPages)} $isActive={totalPages === currentPage}>
          {totalPages}
        </PageNumber>
      </>
    );
  };

  const showDotsLeft = () => {
    return (
      <>
        <PageNumber onClick={() => changePage(1)} $isActive={currentPage === 1}>
          1
        </PageNumber>
        <Dots $isActive={false}>...</Dots>
        {Array.from({ length: 3 }, (_, i) => totalPages - 2 + i).map((pageNumber) => (
          <PageNumber key={pageNumber} onClick={() => changePage(pageNumber)} $isActive={pageNumber === currentPage}>
            {pageNumber}
          </PageNumber>
        ))}
      </>
    );
  };

  const showDotsBoth = () => {
    return (
      <>
        <PageNumber $isActive={currentPage === 1} onClick={() => changePage(1)}>
          1
        </PageNumber>
        <Dots $isActive={false}>...</Dots>
        <PageNumber $isActive={false} onClick={() => changePage(currentPage - 1)}>
          {currentPage - 1}
        </PageNumber>
        <PageNumber $isActive>{currentPage}</PageNumber>
        <PageNumber $isActive={false} onClick={() => changePage(currentPage + 1)}>
          {currentPage + 1}
        </PageNumber>
        <Dots $isActive={false}>...</Dots>
        <PageNumber $isActive={false} onClick={() => changePage(totalPages)}>
          {totalPages}
        </PageNumber>
      </>
    );
  };

  return (
    <PaginationContainer>
      <IconContainer onClick={() => currentPage > 1 && navLeft()} $active={currentPage !== 1}>
        <Icon IconType={FaChevronLeft} size={'sm'} />
      </IconContainer>
      {totalPages < 7 && showAllNumbers()}
      {totalPages > 7 && (currentPage === 1 || currentPage === 2) && showDotsRight()}
      {totalPages > 7 && (currentPage === totalPages || currentPage === totalPages - 1) && showDotsLeft()}
      {totalPages > 7 && currentPage > 2 && currentPage < totalPages - 1 && showDotsBoth()}
      <div
        onClick={() => currentPage < totalPages && navRight()}
        style={currentPage === totalPages ? { color: 'gray' } : {}}
      >
        <IconContainer $active={currentPage !== totalPages}>
          <Icon IconType={FaChevronRight} size={'sm'} />
        </IconContainer>
      </div>
    </PaginationContainer>
  );
};
