import { NavLink } from 'react-router-dom';
import { styled } from 'styled-components';
import { useAuthentication } from '../../auth';

const NavContainer = styled.nav`
  display: flex;
  gap: 1em;
`;

const NavOption = styled(NavLink)`
  cursor: pointer;
  padding: 0.5em 1em;
  width: 100px;
  text-align: center;
  text-decoration: none;
  border-radius: 12px;
  color: ${(props) => props.theme.typography.colors.primary};
  font-size: 1em;
  font-weight: bold;
  border: 2px solid transparent;
  box-shadow: 0 0 0 2px #ffffff00;

  &[aria-current] {
    background: ${(props) => props.theme.typography.colors.primary};
    color: ${(props) => props.theme.typography.colors.secondary};
    border: 2px solid ${(props) => props.theme.typography.colors.secondary};
    box-shadow: 0 0 0 2px #ffffff99;
  }
`;

export const Nav = () => {
  const { roles } = useAuthentication();

  return (
    <NavContainer>
      <NavOption to="/" state={{ resetView: true }}>
        Home
      </NavOption>

      <NavOption to="/patients" state={{ resetView: true }}>
        Patients
      </NavOption>

      {roles.includes('admin') && (
        <NavOption to="/admin" state={{ resetView: true }}>
          Admin
        </NavOption>
      )}
    </NavContainer>
  );
};
