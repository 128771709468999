import { styled } from 'styled-components';
import { PatientActivityLog } from '../../../api';
import { Tile } from '../../../components/Tile.tsx';
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '../../../components/table/index.tsx';
import { activityLabelsById, gameLabelsById } from '../../../constants.ts';

const ActivityLogTile = styled(Tile)`
  display: flex;
  max-height: 100%;
  min-width: 100%;

  > h2 {
    margin-bottom: 0.5rem;
  }
`;

type ActivityLog = {
  id: number;
  activityName: string;
  completedOn: Date;
  timeOnTask: number;
  rpe?: number;
  repsCompleted?: number;
  independenceScore?: number;
  enduranceScore?: number;
};

const formatDate = (date: Date) => {
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}
  ${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

const formatTime = (time: number) => {
  return `${Math.floor(time / 60)}:${(time % 60).toString().padStart(2, '0')}`;
};

const getRepsCompleted = (activityLog: PatientActivityLog): number | undefined => {
  if ('gameData' in activityLog) {
    return activityLog.gameData.reduce((acc, data) => {
      return acc + (data?.repsCompleted ?? 0);
    }, 0);
  }

  return activityLog.repsCompleted;
};

const getActivityLabel = (activityLog: PatientActivityLog): string => {
  const activityLabel = activityLabelsById[activityLog.activityType];

  if ('gameId' in activityLog) {
    const gameLabel = gameLabelsById[activityLog.gameId] ?? 'Unknown';
    return `${activityLabel}: ${gameLabel}`;
  }

  return activityLabel ?? 'Unknown';
};

export const ActivityLogContent = (activityLogs: PatientActivityLog[]) => {
  const logs: ActivityLog[] = activityLogs.map((log) => ({
    id: log.id,
    activityName: getActivityLabel(log),
    completedOn: new Date(log.completedOn),
    timeOnTask: log.timeTaken,
    repsCompleted: getRepsCompleted(log),
    rpe: log.feedbackScore,
    independenceScore: 'independenceScore' in log ? log.independenceScore : undefined,
    enduranceScore: 'enduranceScore' in log ? log.enduranceScore : undefined
  }));

  return (
    <ActivityLogTile title={'Recent Activity'}>
      <div className="max-h-[550px]">
        <Table fixedHeader>
          <TableHeader>
            <TableColumn>Date</TableColumn>
            <TableColumn>Activity</TableColumn>
            <TableColumn>Duration</TableColumn>
            <TableColumn>Repetitions</TableColumn>
            <TableColumn>RPE</TableColumn>
            <TableColumn>Independence Score</TableColumn>
            <TableColumn>Endurance Score</TableColumn>
          </TableHeader>
          <TableBody>
            {logs.map((log) => (
              <TableRow key={log.id}>
                <TableCell>{formatDate(log.completedOn)}</TableCell>
                <TableCell>{log.activityName}</TableCell>
                <TableCell>{formatTime(log.timeOnTask)}</TableCell>
                <TableCell>{log.repsCompleted}</TableCell>
                <TableCell>{log.rpe ?? ''}</TableCell>
                <TableCell>{log.independenceScore ?? ''}</TableCell>
                <TableCell>{log.enduranceScore ?? ''}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </ActivityLogTile>
  );
};
