import { useEffect, useRef, useState } from 'react';
import { IoMdAt, IoMdLogOut, IoMdUnlock } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { styled } from 'styled-components';
import { redirectToLogout, useAuthentication } from '../../auth';
import { ResetPasswordModal } from '../../auth/views/ResetPasswordModal.tsx';
import { UpdateEmailModal } from '../../auth/views/UpdateEmailModal';
import { resetAll } from '../../store/actions.ts';
import { Button } from '../button';
import { NoCapture } from '../NoCapture';

const ProfileOuter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Profile = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colours.accent};
  color: ${(props) => props.theme.colours.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;

  &:hover {
    filter: brightness(1.2);
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 80px;
  background-color: ${(props) => props.theme.colours.backgroundDark};
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
  padding: 16px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DropdownButton = styled(Button)`
  width: 100%;
  white-space: nowrap;
  display: block;
`;

// TODO: Create popover component and use here
export const ProfileMenu = () => {
  const user = useAuthentication();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event: { target: EventTarget | null }) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        profileRef.current &&
        !profileRef.current.contains(event.target as Node)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const getInitials = () => {
    if (user.name) {
      const names = user.name.split(' ');
      return names[0].charAt(0).toUpperCase() + names[names.length - 1].charAt(0).toUpperCase();
    }
    return '';
  };

  const signOutUser = async () => {
    dispatch(resetAll());
    redirectToLogout();
  };

  return (
    <ProfileOuter>
      {showResetPasswordModal && (
        <ResetPasswordModal userId={user.id} onClose={() => setShowResetPasswordModal(false)} />
      )}
      {showUpdateEmailModal && <UpdateEmailModal user={user} onClose={() => setShowUpdateEmailModal(false)} />}
      <Profile ref={profileRef} onClick={() => setShowMenu(!showMenu)}>
        {getInitials()}
      </Profile>
      {showMenu && (
        <Dropdown ref={dropdownRef}>
          <div className="cursor-default text-center text-lg font-semibold">
            <NoCapture>{user.name}</NoCapture>
          </div>
          <hr className="text-highlight my-4 w-full" />
          <DropdownButton
            variant="transparent"
            icon={IoMdUnlock}
            iconLayout="start"
            onClick={() => {
              setShowResetPasswordModal(true);
              setShowMenu(false);
            }}
          >
            Reset password
          </DropdownButton>
          <DropdownButton
            variant="transparent"
            icon={IoMdAt}
            iconLayout="start"
            onClick={() => {
              setShowUpdateEmailModal(true);
              setShowMenu(false);
            }}
          >
            Update email
          </DropdownButton>
          <DropdownButton variant="transparent" icon={IoMdLogOut} iconLayout="start" onClick={signOutUser}>
            Sign out
          </DropdownButton>
        </Dropdown>
      )}
    </ProfileOuter>
  );
};
