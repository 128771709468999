import { Navigate, Route, Routes } from 'react-router-dom';
import { UsersList } from './views/UsersList.tsx';
import { useAuthentication } from '../auth';
import { NotFound } from '../NotFound.tsx';

export const Admin = () => {
  const { roles } = useAuthentication();

  if (!roles.includes('admin')) {
    return <NotFound />;
  }

  return (
    <Routes>
      <Route index element={<Navigate to="/admin/users" replace={true} state={{ resetView: true }} />} />
      <Route path="/users" element={<UsersList />} />
    </Routes>
  );
};
