import { ListViewColumnDefinition, ListViewFieldProps, ListViewRow, ListViewSorting } from './ListView.tsx';

export function rowsFromColumnDefinitions(
  data: object[],
  columnDefinitions: ListViewColumnDefinition[]
): ListViewRow[] {
  return data.map((dataItem: object) => {
    const entries = Object.entries(dataItem);
    const entriesWithColumnDefinitions = entries.filter(([key]) => {
      return columnDefinitions.some((columnDefinition) => columnDefinition.field === key);
    });

    const entryKeys = entriesWithColumnDefinitions.map(([key]) => key);

    const columnsWithoutEntry = columnDefinitions.filter((f) => {
      return !entryKeys.includes(f.field);
    });

    const fields = entriesWithColumnDefinitions.map(([key, value]) => {
      const columnDef = columnDefinitions.find((c) => c.field === key)!;
      const component = columnDef.fieldComponent
        ? columnDef.fieldComponent
        : ({ value, column }: ListViewFieldProps) => <>{value || column.defaultValue}</>;

      return {
        field: key,
        columnDefinition: columnDef,
        dataItem: dataItem,
        value: value,
        component: component
      };
    });

    const emptyFields = columnsWithoutEntry.map((c) => {
      return {
        field: c.field,
        columnDefinition: c,
        dataItem: dataItem,
        value: c.defaultValue,
        component: c.fieldComponent
          ? c.fieldComponent
          : ({ value, column }: ListViewFieldProps) => <>{value || column.defaultValue}</>
      };
    });

    return {
      fields: fields.concat(emptyFields),
      data: dataItem
    };
  });
}

export const applyRowSorting = (rows: ListViewRow[], sorting: ListViewSorting) => {
  rows.sort((rowA, rowB) => {
    const fieldA = rowA.fields.find(({ field }) => field === sorting.field);
    const fieldB = rowB.fields.find(({ field }) => field === sorting.field);

    if (!fieldA || !fieldB) {
      return 0;
    }

    if (fieldA.value && fieldB.value) {
      if (fieldA.value < fieldB.value) {
        return sorting.ascending ? -1 : 1;
      }

      if (fieldA.value > fieldB.value) {
        return sorting.ascending ? 1 : -1;
      }
    }

    return 0;
  });
};
