import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { activityLabelsById, ActivityType, gameLabelsById } from '../constants';

/**
 * Merge CSS class names together
 *
 * @param inputs the collection of strings containing class names
 * @returns the merged class names
 */
export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

type RGB = {
  r: number;
  g: number;
  b: number;
};

/**
 * Parse a color string into an RGB object
 *
 * @param color The color to parse, either in the format rgb(r,g,b) or #RRGGBB
 * @returns The RGB object containing the red, green, and blue values
 */
export function parseRGB(color: string): RGB {
  const rgbMatch = color.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
  if (rgbMatch) {
    return {
      r: parseInt(rgbMatch[1], 10),
      g: parseInt(rgbMatch[2], 10),
      b: parseInt(rgbMatch[3], 10)
    };
  }

  const hex = color.replace('#', '');
  if (hex.length === 6) {
    return {
      r: parseInt(hex.substring(0, 2), 16),
      g: parseInt(hex.substring(2, 4), 16),
      b: parseInt(hex.substring(4, 6), 16)
    };
  }

  throw new Error('Invalid color format. Use rgb(r,g,b) or #RRGGBB');
}

/**
 * Interpolate between two colors
 *
 * @param index the index of the color in the gradient
 * @param total the total number of colors in the gradient
 * @param fromColor the starting color of the gradient
 * @param toColor the ending color of the gradient
 * @returns a color string in the format rgb(r,g,b)
 */
export function interpolateColor(
  index: number,
  total: number,
  fromColor: string = '#00dced',
  toColor: string = '#006eff'
): string {
  const start = parseRGB(fromColor);
  const end = parseRGB(toColor);
  const factor = index / (total - 1);

  const r = Math.round(start.r - (start.r - end.r) * factor);
  const g = Math.round(start.g - (start.g - end.g) * factor);
  const b = Math.round(start.b - (start.b - end.b) * factor);

  return `rgb(${r},${g},${b})`;
}

export function getActivityLabel({ activityTypeId, gameId }: { activityTypeId: string; gameId?: string }): string {
  if (activityTypeId === ActivityType.GAME_MODE) {
    return gameId ? `${gameLabelsById[gameId]} Game` : '';
  }

  return activityLabelsById[activityTypeId] ?? '';
}

export function calculatePercentageChange(current: number, previous: number): number {
  if (current === 0 && previous === 0) {
    return 0;
  } else if (previous === 0) {
    return 100;
  } else if (current === 0) {
    return -100;
  }

  return ((current - previous) / previous) * 100;
}

export function toUtcMidnight(date: Date): Date {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
}

export function getKeyValueString(obj: object): string {
  return Object.entries(obj)
    .map(([key, value]) => `${key}:${value}`)
    .join(',');
}

export function getQueryCacheKey(params: object, prefix = ''): string {
  let key = getKeyValueString(params);
  if (prefix) {
    key = `${prefix}-${key}`;
  }
  return key;
}

export function buildQueryParams(obj: object): string {
  const params = new URLSearchParams();

  Object.entries(obj).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      params.append(key, String(value));
    }
  });

  const queryString = params.toString();
  return queryString ? `?${queryString}` : '';
}
